<template>
  <div>
    <b-modal
      id="finished-trait-support"
      ref="finished-trait-support"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-overlay :show="show" rounded="sm">
        <div class="d-block py-2 px-5 invite-friends-modal">
          <div class="text-center">
            <p class="color-mhc-dark">{{ username }} completed</p>
            <h3 class="mb-1">{{ title }}</h3>
            <p class="pb-2">mental health check and scored {{ score }}</p>

            <pie-chart :data="circleChart" :chartColor="circleChart.color" />

            <p class="pt-2">Leave a comment below and send an emoji to show support.</p>
          </div>

          <div class="text-center">
            <div class="d-flex justify-content-center">
              <reactions :data="data" withSpaces="true" />

              <span class="ml-1">
                <follow-button :data="data" />
              </span>
            </div>
          </div>
          <b-collapse
            id="collapse-reaction-message"
            class="mt-2"
            :visible="true"
          >
            <b-form-group label="Message" label-for="message" class="text-left">
              <b-form-textarea v-model="message" id="message" placeholder="Message" rows="3" :state="message.length > 0" />
            </b-form-group>
            <div class="text-center">
              <b-button variant="primary" class="mt-2 w-75 py-1" @click="sendMessage(id)" :disabled="isDisabled"
                >Send Message</b-button
              >
            </div>
          
          </b-collapse>
        </div>
        
        <users-messages :key="messagesKey" v-if="id" :id="id" :fromFinishedTriatSupport="true" />
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import EventBus from '../../main.js';
import {
  BOverlay,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BFormTextarea,
  BFormRadio,
  BButton,
  BLink,
  BForm,
  BRow,
  BCol,
  BInputGroup,
  BBadge,
  BCollapse, VBToggle, 
} from "bootstrap-vue";
import FollowButton from '@/components/users/FollowButton.vue'
import Reactions from '../users/Reactions.vue';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UsersMessages from '@/components/questionnaires/UsersMessages.vue'
import PieChart from '@/components/my-hub/PieChart.vue'

export default {
  components: {
    BOverlay,
    BFormInput,
    BFormSelect,
    BModal,
    BFormRadio,
    BButton,
    BFormTextarea,
    BLink,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
    BBadge,
    BCollapse, 
    VBToggle,
    FollowButton,
    Reactions,
    UsersMessages,
    PieChart
  },
  computed: {
    isDisabled() {
      if(this.message.length > 0) {
        return false
      } else {
        return true
      }
    }
  },
  data() {
    return {
      message: "",
      data: {},
      username: "",
      title: "",
      score: "",
      reactions: [],
      id: "",
      show: true,
      messagesKey: 0,
      circleChart: {
        series: [20, 80],
        color: "#59949c"
      },
    };
  },
  directives: {
    'b-toggle': VBToggle,
  },
  methods: {
    // Send message reaction
    sendMessage(id) {
      let requestParams = {
        "reaction_text": this.message,
        "user_questionnaire_id": id
      }

      this.$http.post("/api/auth/user/reactions/message", requestParams)
      .then((res) => {
        if(res != undefined) {
          this.message = ""
          // Toggle (close) opened collapse
          this.$root.$emit('bv::toggle::collapse', 'collapse-reaction-message')

          // Emit newUserMessageLeft event
          EventBus.$emit('newUserMessageLeft', id);

          // Show message
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                "Support comment sent!",
                icon: "CheckSquareIcon",
                variant: "success",
            },
          });
        }
      })
      .catch((err) => {
          // Show message
          let errMsg = ""
          if(err.response.data.message != null && err.response.data.message != undefined) {
            if(err.response.data.message == "The given data was invalid.") {
              errMsg = "Swear and bad words not allowed"
            } else {
              errMsg = err.response.data.message
            }
          } else {
            errMsg = "Something went wrong"
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errMsg,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
      })

      // Reset message field
      this.message = ""
    },

    // Get Finished Trait data
    getFinishedTraitSupportData(id) {
      this.$http.get("/api/auth/questionnaire/results/related/single/" + id)
        .then((res) => {
          if(res != undefined) {
            this.data = res.data
            this.username = res.data.userquestionnairetook.user.username
            this.title = res.data.userquestionnairetook.questionnaire.subcategory.name
            this.score = res.data['Questionnaire Score'].section_score
            this.reactions = res.data.reactions
            this.id = res.data.id
            this.show = false
            this.messagesKey++;

            // Pie chart
            this.circleChart.color = res.data['Questionnaire Score'].section_score_color
            
            this.circleChart.series = [
              this.score,
              10 - this.score,
            ].reverse();
            
            if(this.score > 9.7) {
              this.circleChart.series = [10, 0.3].reverse();
            }

            if(this.score == 0) {
              this.circleChart.series = [10, 0.3];
            }
          }
        })
        .catch((err) => {
            console.log(err)

            // Show toast
            this.$toast({
              component: ToastificationContent,
              props: {
                  title: "Something went wrong. Please try again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
              },
            });
        })
    }
  },
  mounted() {
    // Event for opening Finished trait support modal
    EventBus.$on('openFinishedTraitSupportModal', (id) => {
      // Show overlay
      this.show = true

      if(this.$refs['finished-trait-support'] != undefined) {
        // Show modal
        this.$refs["finished-trait-support"].show();
        
        // Get fresh data for modal
        this.getFinishedTraitSupportData(id)
      }

    });

    // Event for getting fresh reactions for Finished trait support modal
    EventBus.$on('getFreshReactionsForFinishedTraitSupport', (id) => {
      // Get fresh data for modal
      this.getFinishedTraitSupportData(id)
    });

  },
};
</script>

<style scoped>
@import "../../assets/css/modals-style.css";
</style>