<template>
    <b-modal
        id="report-modal"
        ref="report-modal"
        hide-footer
        centered
        size="md"
        >
        <b-overlay :show="show" rounded="sm" class="pt-2">
            <p class="font-weight-normal">Please choose type of your report</p>
            <b-form-select
                v-model="selectedReportType"
                :options="reportTypes"
            />
            <div class="d-block py-2 px-1 text-center">
                <b-button @click="reportMessage" variant="primary" class="mt-2">Report message</b-button>
            </div>
        </b-overlay>
    </b-modal>
</template>

<script>
import EventBus from '@/main'
import { BOverlay, BFormSelect, BModal, BButton } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BOverlay,
        BFormSelect,
        BModal,
        BButton
    },
    data() {
        return {
            show: true,
            reportTypes: [],
            reactionId: null,
            selectedReportType: ""
        }
    },
    methods: {
        // Get Finished Trait data
        getReportTypes() {
            this.$http.get("/api/auth/report-types")
            .then((res) => {
                if(res != undefined) {
                    let allReportTypes = []

                    // Get just important data (id and name)
                    for(let i=0; i<res.data.length; i++) {
                        let objReportType = {
                            value: res.data[i].id,
                            text: res.data[i].name
                        }
                        allReportTypes.push(objReportType)
                    }
                    // Set data
                    this.reportTypes = allReportTypes
                    this.selectedReportType = allReportTypes[0].value
                    
                    this.show = false
                }
            })
            .catch((err) => {
                console.log(err)

                // Show toast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong. Please try again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });

                this.show = false
            })
        },
        reportMessage() {
            this.$http.post("/api/auth/reaction/report/"+this.reactionId+"/"+this.selectedReportType)
            .then((res) => {
                // Show toast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Message successfully reported",
                        icon: "CheckSquareIcon",
                        variant: "success",
                    },
                });

                // Emit report message - get fresh list of messages
                EventBus.$emit('messageReported')
                // Close modal
                this.$refs["report-modal"].hide();
            })
            .catch((err) => {
                console.log(err)

                // Show toast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong. Please try again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });

                this.show = false
            })
        }
    },
    mounted() {
        // Event for opening Report message modal
        EventBus.$on('openReportModal', (id) => {
            // Show overlay
            this.show = true
            this.reactionId = id

            if(this.$refs['report-modal'] != undefined) {
                //Show modal
                this.$refs["report-modal"].show();
                
                //Get fresh data for modal
                this.getReportTypes()
            }
        });
    }
};
</script>

<style scoped>
@import "../../assets/css/modals-style.css";
</style>