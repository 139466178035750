<template>
  <div>
    <vue-apex-charts
      ref="circleChart"
      height="200"
      :options="circleChart.chartOptions"
      :series="data.series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

const $strokeColor1 = "#ededed";
const $strokeColor2 = "#59949c";

export default {
  components: {
    VueApexCharts,
  },
  props: ["data", "chartColor"],
  mounted() {
    if(this.chartColor != undefined && this.chartColor != null && this.chartColor != "") {      
      this.$refs.circleChart.updateOptions({
        colors: [$strokeColor1, this.chartColor],
      });
    }
  },
  data() {
    return {
      valueToDisplayInChart: 10,
      circleChart: {
        chartOptions: {
          tooltip: {
            enabled: false,
          },
          chart: {
            type: "donut",
            toolbar: {
              show: false,
            },
            animations: {
              enabled: false,
            },
            selection: {
              enabled: false,
            },
          },
          states: {
            hover: {
              filter: {
                type: "none",
              },
            },
            active: {
              filter: {
                type: "none",
              },
            },
          },
          dataLabels: {
            enabled: false,
            enabledOnSeries: undefined,
          },
          legend: { show: false },
          labels: ["of 10", "of 10"],
          stroke: { width: 0 },
          colors: [$strokeColor1, $strokeColor2],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 20,
                    fontSize: "20px",
                    label: "out of 10",
                  },
                  value: {
                    offsetY: -20,
                    fontSize: "20px",
                    label: "out of 10",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    showAlways: true,
                    label: "out of 10",
                    fontWeight: 300,
                    offsetY: -10,
                    formatter(value, opts) {
                      let pom = value.globals.series;

                      if (pom[0] == 10 && pom[1] == 0.3) {
                        return 0;
                      }

                      if (pom[0] == 10 || pom[1] == 10) {
                        return 10;
                      }
                      return pom[1];
                    },
                  },
                },
              },
            },
          },
        },
      },
    };
  },
  watch: {
    $props: {
      handler(val) {
        if (this.$refs.circleChart != undefined) {
          this.$refs.circleChart.updateOptions({
            colors: [$strokeColor1, this.chartColor],
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>