<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <!-- <how-it-works size="sm" :show="false" /> -->
      <about-us size="sm" :show="false" />
      <what-we-do size="sm" :show="false" />
      <science size="sm" :show="false" />
      <invite-friends :show="false" />
      <pricing />
      <daily-check-in />
      <set-username size="sm" v-if="this.show_username_modal == true" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      
      <div class="upgrade-plan mr-1 d-none d-sm-block" v-if="showUpgrade" @click="upgrade">UPGRADE</div>

      <follow-requests-dropdown />
      <notification-dropdown />
      <least-three-checks />
      <least-three-invites />

      <low-scores-modal />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userData.name}}
            </p>
            <span class="user-status">{{ userData.username }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'profile'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>
        
        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'followers'}">
          <feather-icon
            size="16"
            icon="UsersIcon"
            class="mr-50"
          />
          <span>Followers</span>
        </b-dropdown-item>
        
        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'subscription'}">
          <feather-icon
            size="16"
            icon="CreditCardIcon"
            class="mr-50"
          />
          <span>Subscription</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import store from '@/store/index'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar
} from 'bootstrap-vue'
import AboutUs from '../../components/modals/AboutUs.vue'
import WhatWeDo from '../../components/modals/WhatWeDo.vue'
import HowItWorks from '../../components/modals/HowItWorks.vue'
import Science from '../../components/modals/Science.vue'
import SetUsername from '../../components/modals/SetUsername.vue'
import NotificationDropdown from './NotificationDropdown.vue'
import FollowRequestsDropdown from './FollowRequestsDropdown.vue'
import LowScoresModal from './LowScoresModal.vue'
import LeastThreeChecks from '../../components/modals/LeastThreeChecks.vue'
import LeastThreeInvites from '../../components/modals/LeastThreeInvites.vue'
import Pricing from '../../components/modals/Pricing.vue'
import InviteFriends from '../../components/modals/InviteFriends.vue'
import DailyCheckIn from "../../components/modals/daily-check-in/DailyCheckIn.vue"
import EventBus from '../../main.js';

//import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    AboutUs,
    WhatWeDo,
    HowItWorks,
    DailyCheckIn,
    Science,
    SetUsername,
    NotificationDropdown,
    FollowRequestsDropdown,
    LeastThreeChecks,
    LeastThreeInvites,
    Pricing,
    InviteFriends,
    LowScoresModal,
    //DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userData() {
      return store.state.app.userData;
    }
  },
  mounted() {
    let username = store.state.app.userData.username;
    if(username == null || username == "" || username == undefined) {
      this.show_username_modal = true;
    }

    // Get current subscription plan
    this.getCurrentSubscriptionPlan()

    EventBus.$on('changeStatusForUpgradeNavButton', () => {
      this.getCurrentSubscriptionPlan()
    })
  },
  data() {
    return {
      show_username_modal: false,
      showUpgrade: false,
      currentSubscriptionPlan: "",
      currentSubscriptionPrice: "",

      // Products
      productFullPackage: store.state.app.productFullPackage
    };
  },
  methods: {
    profile() {
      // Open profile page
      this.$router.push("/profile")
    },
    logout() {            
      this.$http.get('/api/logout')
      .then(response => {        
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })
      .catch(error => {
        console.log(error)
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })

      // Remove user data and redirect to login page
      store.commit('app/removeUserData')
      localStorage.removeItem('userData');
      this.$router.push("/login")
    },
    // Get current subscription plan
    getCurrentSubscriptionPlan() {
        this.$http.get('/api/auth/payment/current-plan')
            .then((response) => {
                if(response != undefined) {
                    this.currentSubscriptionPlan = response.data.subscriptions
                    if(this.currentSubscriptionPlan.length == 0) {
                        this.currentSubscriptionPrice = null
                    } else {
                        this.currentSubscriptionPrice = response.data.subscriptions[0].stripe_price
                    }

                    // Show or hide Upgrade button in Navbar
                    if(this.currentSubscriptionPrice != this.productFullPackage) {
                      this.showUpgrade = true
                    } else {
                      this.showUpgrade = false
                    }
                }
            })
            .catch((err) => {
                console.log(err)
            })
    },
    upgrade() {
      this.$router.push("/change-subscription-plan");
    }
  },
}
</script>

<style scoped>
.px-05 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.upgrade-plan {
  padding: 8px 15px;
  border-radius: 10px;
  background-color: #ff9f43;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}
</style>