<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="count"
        badge-classes="bg-mhc-dark"
        class="text-body"
        icon="UserPlusIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Follow requests
        </h4>
        <b-badge
          pill
          variant="mhc-dark"
        >
          {{ count }} New
        </b-badge>
      </div>
    </li>

    <!-- Follow Requests -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-overlay :show="show" rounded="sm">
        <div v-for="(follower, index) in followers" :key="follower.id">
            <!-- <b-media v-if="follower.accepted == 0 && follower.banned == 0"> -->
            <b-media>
              <template #aside>
                <b-avatar
                  size="32"
                  :src="makeAvatar(follower.following_u.username)"
                  variant="light-info"
                />
              </template>
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <p class="media-heading">
                    <span class="font-weight-bolder">
                      {{ follower.following_u.username }}
                    </span>
                  </p>
                  <div class="mt-05">
                  <span>
                      <b-badge
                        pill
                        variant="light-primary"
                        @click="accept(follower.id, index)"
                        class="cursor-pointer"
                        >
                        Accept
                        </b-badge>
                  </span>
                  <span class="ml-1">
                      <b-badge
                        pill
                        variant="light-danger"
                        @click="decline(follower.id, index)"
                        class="cursor-pointer"
                        >
                        Decline
                        </b-badge>
                  </span>
                  <span class="ml-1">
                      <b-badge
                        pill
                        variant="light-dark"
                        @click="ban(follower.following_u.id, index)"
                        class="cursor-pointer"
                        >
                        Ban
                        </b-badge>
                  </span>
                  </div>
                </div>
                <div>
                  <small>{{ follower.created_at | prepareDate }}</small>
                </div>
              </div>
            </b-media>
        </div>
      </b-overlay>
    </vue-perfect-scrollbar>

    <!-- Footer -->
    <li class="dropdown-menu-footer">
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      v-if="followers.length > 0"
      >
        See all requests
      </b-button>
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="inviteFriends"
      v-else
      >
        Invite friends
      </b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import store from '@/store/index'
import {
  BNavItemDropdown, BOverlay, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import EventBus from '../../main.js';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BOverlay,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    EventBus
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      followers: {},
      count: 0,
      show: false,
    }
  },
  methods: {
    subscribe () {
      let pusher = store.state.app.pusher
      pusher.subscribe('private-user-' + store.state.app.userData.username)
      // New reaction added and new message reaction added
      pusher.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
        // Append new notification
        if(data.type == 'follow-request-sent') {
          // Append new follow request
          this.followers = [...this.followers, data.core]
          this.count++
        } else if(data.type == 'follow-request-withdrawn') {
          // if a user has withdrawn a follow request
          // just fetch everything again from the API
          this.getFollowRequests();
        }
      })
    },

    // Accept follow request
    accept(follower_id, index) {
      this.show = true
      this.$http.get("/api/auth/user/follow-requests/accept/" + follower_id)
        .then((res) => {
          // Remove item from list
          this.followers.splice(index, 1)
          this.count--
          this.show = false

          // Emit followRequestAccepted event
          EventBus.$emit('followRequestAccepted');
        })
        .catch((err) => {
          console.log(err)
          
          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        })
    },

    // Decline follow request
    decline(follower_id, index) {
      this.show = true
      this.$http.delete("/api/auth/user/follow-requests/decline/" + follower_id)
        .then((res) => {
          // Remove item from list
          this.followers.splice(index, 1)
          this.count--
          this.show = false
        })
        .catch((err) => {
          console.log(err)

          this.show = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        })
    },

    // Ban user
    ban(follower_id, index) {
      this.$bvModal
            .msgBoxConfirm('Are you sure you want to block this user?', {
            title: 'Are your sure?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {        
            if(value == true) {
              this.show = true
              this.$http.get("/api/auth/user/follow-requests/ban/" + follower_id)
                .then((res) => {
                  // Remove item from list
                  this.followers.splice(index, 1)
                  this.count--
                  this.show = false

                  // Emit userBanned event
                  EventBus.$emit('userBanned');
                })
                .catch((err) => {
                  console.log(err)

                  this.show = false
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Something went wrong. Please try again",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                })
            }
        });      
    },
    
    // Get all follow requests
    getFollowRequests() {// test commit
      this.$http.get("/api/auth/user/follow-requests")
      .then((res) => {
        if(res != undefined) {
          // Fill followers
          this.followers = res.data.followers
          // Fill followers counter
          this.count = res.data.followers.length
        }
      })
      .catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      })
    },

    // Open Invite friends modal
    inviteFriends() {
      EventBus.$emit('inviteFriends')
    }
  },
  created() {
    this.subscribe();
  },
  mounted() {
    // Get all follow requests
    this.getFollowRequests();
  },
  watch: {
    followers(value) {
      this.followers = value
    },
  },
}
</script>

<style>

</style>
