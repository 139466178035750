<template>
  <!-- If status is Banned we don't need to show button -->
  <div v-if="following.is_following != 'banned'">
    <b-badge
      pill
      variant="light-mhc"
      class="cursor-pointer"
      @click="badgeClicked"
    >
      <feather-icon :icon="icon" size="14" /> {{ text }}
    </b-badge>
  </div>
</template>

<script>
import EventBus from '@/main'
import { BBadge } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BBadge,
  },
  props: ["data"],
  data() {
    return {
      following: {},
      icon: "",
      text: "",
      follow_id: null
    };
  },
  methods: {
    // Set badge icon and text based on status - fn called beforeMount
    setBadgeBasedOnStatus() {
      if(this.following != undefined) {
        if (this.following.is_following == "no-follow") {
          // If users not following
          this.icon = "UserPlusIcon";
          this.text = "Follow";
        } else if (this.following.is_following == "requested") {
          // If follow sent but not received yet
          this.icon = "ClockIcon";
          this.text = "Pending";
        } else if (this.following.is_following == "reactions_only") {
          // If users in reaction relationship
          this.icon = "UserPlusIcon";
          this.text = "Follow";
        } else {
          this.icon = "UserMinusIcon";
          this.text = "Unfollow";
        }
      }
    },
    // Change  badge icon and text, and send request when badge clicked
    badgeClicked() {
      if (this.text == "Follow") {
        this.icon = "ClockIcon";
        this.text = "Pending";

        // Request to follow
        this.$http
          .get("/api/auth/user/follow/" + this.following.userquestionnairetook.user.id)
          .then((res) => {
            if(res != undefined) {
              EventBus.$emit('followStatusChanged');
              this.follow_id = res.data.id
            }
          })
          .catch((err) => {
            console.log(err);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });

      } else if (this.text == "Pending") {
        this.icon = "UserPlusIcon";
        this.text = "Follow";

        // Set follow_id
        if(this.follow_id == null) {
            this.follow_id = this.following.follow_value.id
        }

        // Request to withdraw follow request
        this.$http
          .delete("/api/auth/user/follow-requests/withdraw/" + this.follow_id)
          .then((res) => {
            EventBus.$emit('followStatusChanged');
          })
          .catch((err) => {
            console.log(err);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });

      } else if ((this.text == "Unfollow")) {
        

        // Set follow_id
        if(this.follow_id == null) {
          this.follow_id = this.following.follow_value.id
        }
        this.$bvModal
            .msgBoxConfirm('Are you sure you want to unfollow this user?', {
            title: 'Are your sure?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {        
            if(value == true) {
            // Request to unfollow user
            this.$http
              .delete("/api/auth/user/follow-requests/unfollow/" + this.follow_id)
              .then((res) => {                
                this.icon = "UserPlusIcon";
                this.text = "Follow";
                EventBus.$emit('followStatusChanged');
              })
              .catch((err) => {
                console.log(err);

                this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "Something went wrong. Please try again",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                  },
                });
              });
            }
        });        
      }
    },
  },
  mounted() {
    this.setBadgeBasedOnStatus()
  },
  watch: { 
    $props: {
      handler(value) {
        this.following = value.data
        this.setBadgeBasedOnStatus()
      },
    deep: true,
    immediate: true,
    },
  }
};
</script>

<style scoped>
</style>