<template>
  <b-modal
    id="set-username-modal"
    ref="set-username-modal"
    hide-footer
    hide-header
    centered
    size="lg"
    no-close-on-backdrop
  >
    <template #modal-title> How it works </template>
    <div class="d-block py-2 px-1">
      <div class="mb-1">
        <p class="font-weight-normal mb-0">Please provide your username</p>
        <small class="cursor-pointer" @click="showToggle">What's this?</small>
        <b-collapse id="collapse-reaction-message">
          <small>By providing a unique username, you can give and receive support, knowing that your profile is hidden from other users. This means you can focus on being your most true and authentic self in a safe and trusting space.</small>
        </b-collapse>
      </div>

      <b-form-input
        id="username"
        v-model="username"
        :state="username.length > 0 && isAvailable"
        placeholder="Username"
        @keyup='checkUsername()'
      />
      <div>
          <small v-if="loading">We are checking, please wait...</small>
          <small v-else class="text-danger">{{ checkUsernameError }}</small>
      </div>

      <b-button
        variant="primary"
        class="mt-2"
        @click="submitUsername"
        :disabled="isDisabled"
        >Submit</b-button
      >
    </div>
  </b-modal>
</template>

<script>
import { BFormInput, BModal, BButton, VBToggle, BCollapse } from "bootstrap-vue";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BFormInput,
    BModal,
    BButton,
    VBToggle,
    BCollapse,
  },
  data() {
    return {
      username: "",
      disabled: true,
      isAvailableUsername: true,
      errors: [],
      loading: false
    };
  },
  directives: { 'b-toggle': VBToggle },
  props: {
      size: {
          type: String
      }
  },
  computed: {
    isDisabled() {
        // Enable/disable submit button
        if(this.isAvailableUsername == true && this.username.length > 0) {
            this.disabled = false
        } else {
            this.disabled = true
        }
        return this.disabled;
    },
    isAvailable() {
        // Check is username available
        return this.isAvailableUsername;
    },
    checkUsernameError() {
        // Check if errors exist
        return this.errors[0]
    },
  },
  methods: {
    showToggle() {
      this.$root.$emit('bv::toggle::collapse', 'collapse-reaction-message')
    },
    checkUsername() {
        this.loading = true
        this.isAvailableUsername = false
        
        setTimeout(() => { 
            let username = this.username.trim()
      
            // If username is not empty lets check if username exists
            if(username != '' && !username.includes(" ")){
                this.$http.get(`/api/user/username/${username}`)
                .then((res) => {
                    this.isAvailableUsername = true
                    this.errors = []                    
                }).catch((error) => {
                    this.isAvailableUsername = false
                    if(error.response.data.message == "Username is taken") {
                      this.errors.push('That username already exists')
                    } else if (error.response.data.message == "The given data was invalid.") {
                      this.errors.push('Swear and bad words not allowed')
                    } else {
                      this.errors.push('Something is wrong, please try again.')
                    }
                })
            } else {
                this.errors.push('The Username field is required and must not contain spaces')
            }

            this.loading = false
        }, 1000);
    },

    // Submit username
    submitUsername() {           
      this.$http.patch(`/api/auth/user/username`, {
        "username": this.username
      }).then((res) => {
        
        this.$refs["set-username-modal"].hide();  
        
        this.$toast({
          component: ToastificationContent,
          props: {
          title:
            "Username successfully set!",
            icon: "CheckSquareIcon",
            variant: "success",
          },
        });
        
        // Setu new username to current sesion
        let userData = store.state.app.userData;
        userData.username = res.data.username;
        userData.avatar = this.makeAvatar(res.data.username)
        store.commit("app/setUserData", userData);

      }).catch((error) => { 
        console.log(error)
        
        this.$refs["set-username-modal"].hide();  
        // Show toast
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });

    }
  },
  mounted() {
    if(this.$refs['set-username-modal'] != undefined) {
      this.$refs["set-username-modal"].show();
    }
  },
};
</script>

<style scoped>
</style>