<template>
    <b-badge
      pill
      :variant="badgeVariant"
      class="cursor-pointer"
      @click="badgeClicked"
    >
      <feather-icon :icon="icon" size="14" /> {{ text }}
    </b-badge>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BBadge,
  },
  props: ["id", "type", "user_id"],
  data() {
    return {
      badgeVariant: "",
      icon: "",
      text: "",
      follow_id: null,
    };
  },
  methods: {
    // Set badge icon and text based on status - fn called beforeMount
    setBadgeBasedOnType() {
      if (this.type == "follow-you") {
        this.icon = "SlashIcon";
        this.text = "Block";
        this.badgeVariant = "light-danger";
      } else if (this.type == "you-follow") {
        this.icon = "UserMinusIcon";
        this.text = "Unfollow";
        this.badgeVariant = "light-primary";
      } else {
        this.icon = "UserMinusIcon";
        this.text = "Unfollow";
        this.badgeVariant = "light-primary";
      }
    },
    // Change  badge icon and text, and send request when badge clicked
    badgeClicked() {
      if (this.text == "Unfollow") {
        this.$bvModal
            .msgBoxConfirm('Are you sure you want to unfollow this user?', {
            title: 'Are your sure?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {        
            if(value == true) {

              // Request for unfollow
              this.$http.delete("/api/auth/user/follow-requests/unfollow/" + this.id)
              .then((res) => {
                if(res != undefined) {     
                  this.icon = "UserPlusIcon";
                  this.text = "Follow";
                  this.follow_id = res.data.id;
                }
              })
              .catch((err) => {
                console.log(err);

                this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: "Something went wrong. Please try again",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                  },
                });
              });
            }
        });
        
      } else if (this.text == "Block") {
        this.$bvModal
            .msgBoxConfirm('Are you sure you want to block this user?', {
            title: 'Are your sure?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Yes',
            cancelTitle: 'No',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
        }).then(value => {        
            if(value == true) {

              // Request for ban user
              this.$http.get("/api/auth/user/follow-requests/ban/" + this.user_id)
                .then((res) => {                  
                  this.icon = "UserCheckIcon";
                  this.text = "Unblock";
                })
                .catch((err) => {
                  console.log(err);

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Something went wrong. Please try again",
                      icon: "AlertCircleIcon",
                      variant: "danger",
                    },
                  });
                });
            }
        });
        

      } else if (this.text == "Follow") {
        this.icon = "ClockIcon";
        this.text = "Pending";

        // Request for follow
        this.$http
          .get("/api/auth/user/follow/" + this.user_id)
          .then((res) => {
          })
          .catch((err) => {
            console.log(err);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });

      } else if (this.text == "Unblock") {
        this.icon = "SlashIcon";
        this.text = "Block";

        // Request for unban user
        this.$http.get("/api/auth/user/follow-requests/unban/" + this.user_id)
          .then((res) => {
          })
          .catch((err) => {
            console.log(err);

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
  watch: {
    type() {
      this.setBadgeBasedOnType();
    },
  },
  beforeMount() {
    this.setBadgeBasedOnType();
  },
};
</script>

<style scoped>
</style>