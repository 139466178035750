<template>
  <div class="p-0">
    <b-link @click="openModal" v-show="show">
      <small v-if="size == 'sm'">About Us</small>
      <div v-else>About Us</div>
    </b-link>

    <b-modal
      id="about-us-modal"
      ref="about-us-modal"
      hide-footer
      centered
      size="xl"
    >
      <template #modal-title>About Us</template>
      <div class="d-block">
        <div class="w-100">
          <!-- content -->
          <section class="deep-blue-bg py-5">
            <h1 class="container px-lg-4 text-center text-white font-size-36">
              Mental Health Check is about making better mental health and
              wellness accessible and achievable every day.
            </h1>
          </section>

          <section class="py-3">
            <article class="container-1020 px-lg-4 text-center">
              <div class="pb-2">
                <b-img
                  fluid
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If you are struggling to speak to anyone about your mental
                health distress and want to feel in control and empowered to
                take those vital steps, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3 light-blue-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If you want to proactively manage your mental health and
                well-being so you spot and address negative patterns before they
                become bigger issues, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3 very-light-gray-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If or while you’re waiting to get seen by your doctor or support
                professional and want to feel more in control and better
                supported, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3 pink-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If or when you have completed the maximum number of counselling
                sessions offered by a public mental health service provider and
                you want more support, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3 light-blue-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If or when you have completed the maximum number of free private
                counselling or therapy sessions paid for by your employer and
                you want more support, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3">
            <article class="container-1020 px-lg-4 text-center">
              <div class="pb-2">
                <b-img
                  fluid
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If or while you are saving up money to pay for private
                counselling or therapy sessions and want to get you mental
                health and well-being in a better place, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3 very-light-gray-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-24">
                This means that ...
              </h2>
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                If you are currently receiving mental health counselling or
                therapy and want more support when you are alone with just your
                thoughts for company, we are here for you.
              </p>
            </article>
          </section>

          <section class="py-3 light-blue-bg">
            <article class="container-1020 px-lg-4 text-center">
              <p
                class="
                  text-dark-gray
                  about-and-what-we-do-p
                  lato-bold
                  font-size-24
                "
              >
                And whenever else you need confidential support, we are here for
                you.
              </p>
            </article>
          </section>
          <!-- content end -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EventBus from "@/main.js";
import { BLink, BModal, BButton, BTable, BImg } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BTable,
    BImg,
  },
  props: {
    size: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    openModal() {
      if (this.$refs["about-us-modal"] != undefined) {
        // Show modal
        this.$refs["about-us-modal"].show();
      }
    },
  },
  mounted() {
    EventBus.$on("openAboutUs", () => {
      this.openModal();
    });
  },
};
</script>

<style scoped>
p {
  font-weight: normal;
}

ol {
  padding-left: 1rem;
}

li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

@media (max-width: 992px) {
  .font-size-36 {
    font-size: 28px;
  }
}
</style>