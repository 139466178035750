<template>
  <div>
    <b-modal
      id="least-three-modal"
      ref="least-three-modal"
      hide-footer
      centered
      size="lg"
      @hidden="closeModal()"
    >
      <b-overlay :show="show" rounded="sm">
        <div class="d-block pt-5 pb-1 px-0 px-lg-5">
          <div class="text-center px-0 px-lg-5">
            <h3 class="mb-1">{{ title }}</h3>
            <p>You have successfully completed your {{ textNumber }} mental health check</p>
            <hr class="mhc-divider m-auto" />
            <h1 class="mb-0 pt-1">{{ score }}</h1>
            <p>out of 10</p>
            <hr class="mhc-divider m-auto" />
            <p class="pt-1" v-html="message"></p>
            <div class="px-3">
              <div class="position-relative mt-3 mx-2">
                <b-progress
                  :value="completedSteps"
                  max="100"
                  variant="mhc-dark"
                  height=".5rem"
                  width="100%"
                  class="mt-2 mb-3"
                />
                <div class="step-1" v-bind:style="{ content: isCompletedStep1 }"></div>
                <div class="step-2" v-bind:style="{ content: isCompletedStep2 }"></div>
                <div class="step-3" v-bind:style="{ content: isCompletedStep3 }"></div>
              </div>
              <b-button v-if="!step3" :to="{name: 'form'}" variant="primary" block class="mt-3 text-uppercase">Start another mental health check</b-button>
              <b-button v-else @click="closeModal()" variant="primary" block class="mt-3 text-uppercase">Continue</b-button>

              <h4 v-if="step3" class="mt-2 mhc-link-underline" @click="inviteFriends()">Invite Friends and Family</h4>
            </div>
            <p class="color-mhc-light mt-3 font-weight-normal cursor-pointer" @click="closeModal()">{{ closeText }}</p>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BModal, BButton, BProgress, BOverlay } from "bootstrap-vue";
import EventBus from "../../main.js"

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BProgress,
    BOverlay
  },
  data() {
    return {
      show: true,
      title: "Welcome to Mental Health Check",
      textNumber: "first",
      message: "Complete <span class='color-mhc-dark'>at least three</span> mental health checks to get the most accurate view of your mental health today.",
      closeText: "Skip",
      step1: true,
      step2: false,
      step3: false,
      completedSteps: 0,
      score: "",
      notification_id: "",
      userFullname: JSON.parse(localStorage.getItem('userData')).name
    }
  },
  computed: {
    isCompletedStep1() {
      if(this.step1) {
        this.completedSteps = 50
        return "url('/check_circle.png')"
      } else {
        this.completedSteps = 0
        return "url('/step-1.png')"
      }
    },
    isCompletedStep2() {
      if(this.step2) {
        this.completedSteps = 100
        this.textNumber = "second"
        return "url('/check_circle.png')"
      } else {
        this.completedSteps = 50
        return "url('/step-2.png')"
      }
    },
    isCompletedStep3() {
      if(this.step3) {
        this.title = "Well done " + this.userFullname
        this.textNumber = "third"
        this.message = "By completing at least three different mental health checks, you will gain better insights into the impact of these life situations on your overall mental health. </p> <p class='mt-1'> Remember that we all have mental health, so be kind to and share mental health check with your  friends and family so they can better understand, easily track and improve their overall mental health and wellbeing in a safe and anonymous way.",
        this.closeText = "Done"
        this.completedSteps = 100
        return "url('/check_circle.png')"
      } else {
        return "url('/step-3.png')"
      }
    }
  },
  methods: {
    openModal() {
      if(this.$refs['least-three-modal'] != undefined) {
        // Show modal
        this.$refs["least-three-modal"].show()
      }
    },
    closeModal() {
      //this.removeNotification(this.notification_id)
      this.$refs["least-three-modal"].hide()
    },
    getLastQuestionnaireResults(notif_id_flag) {
      this.$http.get("/api/auth/user/last-questionnaire")
      .then((res) => {
        if(res != undefined) {
          // Results of last questionnaire
          this.score = res.data.result['Questionnaire Score'].section_score

          // Number of completed questionnaires
          if(res.data.number_of_completed_questionnaires == 1) {
            this.step1 = true
            this.step2 = false
            this.step3 = false
          } else if(res.data.number_of_completed_questionnaires == 2) { 
            this.step1 = true
            this.step2 = true
            this.step3 = false
          } else {
            this.step1 = true
            this.step2 = true
            this.step3 = true
          }

          // Hide overlay
          this.show = false
        }

        if(notif_id_flag == '') {          
          this.openModal();
        } else {
          // if the step 3 is true
          if(this.step3 == true) {          
            // check if it should be show
            if(this.checkIfUserShouldBeShownModal()) {
                this.openModal();
                // if it should be show, show it and if neccessary set the step_3_shown value in the localStorage
                if(localStorage.getItem("step_3_shown") == null) {          
                  localStorage.setItem("step_3_shown", "yes");
                }
            }
          } else {
            // if it isn't about step three, then just show the modal in case the view is the my-hub one
            if(this.$route.name == "my-hub") {
              this.openModal();
            }
          }
        }    
      })
      .catch((err) => {
        console.log(err)
        // Hide overlay
        this.show = false
      })
    },
    // Open modal for invite friends
    inviteFriends() {
      EventBus.$emit('inviteFriends')
    },
    removeNotification(id) {
      this.$http.get("/api/auth/user/notifications-mark/" + id)
      .then((res) => {
        // Hide modal
        this.$refs["least-three-modal"].hide()
      }).catch((err) => {
        console.log(err)
        // Hide modal
        this.$refs["least-three-modal"].hide()
      });
    },    
    checkIfUserShouldBeShownModal() {
      if(localStorage.getItem("step_3_shown") == null && this.$route.name == "my-hub") {
        return true;
      }
      return false;
    },
  },
  mounted() {
    EventBus.$on("leastThreeChecks", (notif_id) => {
      // Show overlay
      this.show = true
      this.notification_id = notif_id
      
      this.getLastQuestionnaireResults(notif_id)
    });
  }
};
</script>

<style scoped>
.step-1 {
  position: absolute;
  top: -7px;
  left: -7px;
}

.step-2 {
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -10px;
}

.step-3 {
  position: absolute;
  top: -7px;
  right: -7px;
}
</style>

<style>
  #least-three-modal___BV_modal_content_ {
    background-color: #f1f1f1 !important;
  }

  #least-three-modal___BV_modal_body_ {
    background-image: url(/main-bg.jpg)!important;
    background-size: contain!important;
    background-repeat: no-repeat;
    background-position: bottom;
  }
</style>