export default [
    {
      title: 'Help & Support',
      route: 'form',
      icon: 'HelpCircleIcon',
      children: [
        /*{ REMOVED ON 01.02.2022. AS REQUESTED
          title: 'How it works',
          route: 'help--how-it-works',
        },*/
        {
          title: 'About Us',
          route: 'help--about-us',
        },
        {
          title: 'What We Do',
          route: 'help--what-we-do',
        },
        {
          title: 'Our Approach',
          route: 'help--the-science',
        },
        {
          title: 'FAQ',
          route: 'faq',
        },
        {
          title: 'Tour',
          route: 'help--tour',
        },
        {
          title: 'Pricing',
          route: 'help--pricing',
        },
      ]
    }
  ]
  