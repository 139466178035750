<template>
    <div>
      <div class="text-center mb-2">
        <h3>
          {{ headingText }}
        </h3>
      </div>
      <div class="questionContainer">
          
        <div class="letterFilter px-1">
          <span class="text-uppercase" 
            v-for="(letter, index) in lettersQuestionnaires" 
            :key="index" 
            @click="firstLetterQuestionnaire = letter; scrollBarToTop()" 
            v-bind:class="{'green-text': letter.flag, 'text-underline': firstLetterQuestionnaire.letter == letter.letter}">
              {{ letter.letter }}
          </span>
        </div>
        
        <div class="px-1" v-if="step == 5">
          <b-form-input v-model="searchQuestionnaire" placeholder="Search questionnaires" class="mb-1"></b-form-input>
        </div>
        
        <vue-perfect-scrollbar
            ref="scroll-bar"
            :settings="perfectScrollbarSettings"
            class="scrollable-container media-list scroll-area"
            style="height: 300px"
            suppressScrollX="false"
          >
            <div class="optionContainer">
              <div class="px-1" v-if="options != undefined">
                <div v-for="option in filteredQuestionnaires" :key="option.id" class="option">
                  <span :class="'option-click ' + isSelected(option)" @click="selectOption(option.id)">
                    <span v-if="option.name != undefined">
                      {{ option.name }}
                    </span>
                    <span v-if="option.title != undefined">
                      {{ option.title }}
                    </span>
                  </span>
                </div>
              </div>
            </div>
        </vue-perfect-scrollbar>
      </div>
    </div>
</template>
<script>
import store from "@/store/index";
import { BRow, BCol, BOverlay, BButton, BModal, BFormSelect, BFormInput } from "bootstrap-vue";
import EventBus from '../../../main.js';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BButton,
    BModal,
    BFormSelect,
    BFormInput,
    ToastificationContent,
    VuePerfectScrollbar
  },
  setup() {
    // Setup perfect scrollbar
    const perfectScrollbarSettings = {
      wheelPropagation: true,
      suppressScrollX: false,
      useBothWheelAxes: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  computed: {
    filteredQuestionnaires() {      
      let searchTerm = this.searchQuestionnaire;
      let letterUsed = this.firstLetterQuestionnaire;
      if(this.options != undefined) {
        return this.options.filter(questionnaire => {

          let title = "";
          if(questionnaire.name != undefined) {
            title = questionnaire.name.toLowerCase();
          } else {
            title = questionnaire.title.toLowerCase();
          }

          if(letterUsed.letter != undefined && letterUsed.letter != "all") {
            if(title.charAt(0).toLowerCase() == letterUsed.letter.toLowerCase()) {
              if(title.includes(searchTerm.toLowerCase())) {
                return questionnaire;
              }
            }
          } else {
            if(title.includes(searchTerm.toLowerCase())) {
              return questionnaire;
            }
          }

        });
      } else {
        return [];
      }      
    }
  },
  props: ["headingText", "options", "step", "option"],
  data() {
    return {
      selectedOption: null,
      searchQuestionnaire: "",
      lettersQuestionnaires: [],
      firstLetterQuestionnaire: "",
      alphabet: ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','x','y','z']
    }
  },
  methods: {
    scrollBarToTop() {
      this.$refs['scroll-bar'].$el.scrollTop = 0
    },
    isSelected(currentOption) {      
      if(currentOption.name != undefined) {
        if(currentOption.name == this.option) {
          return "bg-light-green";
        }
      } else {
        if(currentOption.title == this.option) {
          return "bg-light-green";
        }
      }      
      return "";
    },
    selectOption(id) {
      this.options.forEach((element) => {
        if(element.id == id) {
          this.selectedOption = element
          EventBus.$emit('dailyCheckInOptionSelected', { 
            "option": this.selectedOption,
            "step": this.step
          })
        }
      })
    },
    getQuestionnaireLetters() {      
      this.lettersQuestionnaires.splice(0, 0, { letter: 'all', flag: true });
      this.firstLetterQuestionnaire = 'all'              
    },
    getQuestionnaires(letter) {
      // Show overlay
      this.filterOverlay = true

      // Clear search form
      this.searchQuestionnaire = ""
      
      this.firstLetterQuestionnaire = letter.letter

      // Call endpoint for all subcategories or for clicked letter
      let url = "/api/questionnaires/" + this.chosenQuestionnaireSubcategory + "?available=1";

      if(letter.flag == true && letter.letter != 'all') {
        url = "/api/questionnaires/first-letter/"+this.chosenQuestionnaireSubcategory+"/"+letter.letter;
      }

      this.$http.get(url)
      .then((res) => {
        if(res != undefined) {
          this.questionnaires = res.data

          // Hide overlay
          this.filterOverlay = false
        }
      })
      .catch((error) => {
        console.log(error);

        // Hide overlay
        this.filterOverlay = false

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    },
  },  
  mounted() {
    
  },
  watch: {
    $props: {
      handler(val) {
        if(val.step == 5) {
          let arrayOfQuestionnaireFirstLetters = [];
          this.options.forEach(questionnaire => {
            if(questionnaire.name != undefined) {
              arrayOfQuestionnaireFirstLetters.push(questionnaire.name.charAt(0).toLowerCase())
            } else {
              arrayOfQuestionnaireFirstLetters.push(questionnaire.title.charAt(0).toLowerCase())
            }
          });
          let uniqueLetters = [...new Set(arrayOfQuestionnaireFirstLetters)]
          let finalArrayOfLetters = [];
          this.alphabet.forEach(letter => {

            let flag = false;
            if(uniqueLetters.includes(letter)) {
              flag = true
            }

            finalArrayOfLetters.push({
              "flag": flag,
              "letter": letter
            });
            
          });
          this.lettersQuestionnaires = finalArrayOfLetters
          this.getQuestionnaireLetters();
        }
      },
      deep: true,
      immediate: true,
    },
  }
};
</script>

<style>
@import "../../../assets/css/style.css";

.fix-height-for-cols {
  margin-bottom: 2rem!important;
  height: 100%!important;
}

.fix-height-for-cols .card {
  height: 100%;
}

.helpBtn {
  max-width:90%;
  text-align: right;
  cursor: pointer;
}

label.custom-control-label:hover {
  /*
  background: #59949c 0% 0% no-repeat padding-box;
  border: 1px solid #59949c;
  border-radius: 8px;
  */
}

.letterFilter {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
}

.letterFilter span {
  color: #bbb;
}

.text-underline {
  text-decoration: underline;
}

.green-text {
  color: #59949c!important;
  font-weight: bold;
  cursor: pointer;
}
</style>