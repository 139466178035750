<template>
  <div class="p-0">
    <b-link @click="openModal" v-show="show">
      <small v-if="size == 'sm'">What We Do</small>
      <div v-else>What We Do</div>
    </b-link>

    <b-modal
      id="what-we-do-modal"
      ref="what-we-do-modal"
      hide-footer
      centered
      size="xl"
    >
      <template #modal-title>What We Do</template>
      <div class="d-block">
        <div class="w-100">
          <!-- content -->

          <section class="deep-blue-bg py-5">
            <h1
              class="
                container
                px-lg-4
                text-center text-white
                font-size-36
              "
            >
              Getting mental health support can be complex. We work hard to keep
              it simple, accessible, and effective.
            </h1>
          </section>

          <section class="py-5">
            <article class="container px-lg-4">
              <div class="row">
                <div class="col-6 d-none d-xl-flex">
                  <b-img
                    fluid
                    :src="
                      require('@/assets/images/pages/what-we-do/What-we-do-One.svg')
                    "
                    alt="we help you"
                  />
                </div>
                <div class="col-12 col-xl-6">
                  <div
                    class="
                      d-flex
                      justify-content-center justify-content-xl-start
                    "
                  >
                    <div
                      class="
                        d-inline-flex
                        justify-content-center
                        align-items-center
                        rounded-circle
                        deep-blue-bg
                        circle-with-number
                        mb-2
                        align-self-center
                      "
                    >
                      <span class="font-size-24 lato-bold text-white">1.</span>
                    </div>
                  </div>
                  <h2
                    class="
                      title-text-blue
                      pb-2 pb-xl-5
                      text-center text-xl-left
                      font-size-30
                    "
                  >
                    We help you easily understand your mental health and
                    wellness
                  </h2>
                  <div class="d-flex d-xl-none justify-content-center">
                    <b-img
                      class="max-h-350"
                      fluid
                      :src="
                        require('@/assets/images/pages/what-we-do/What-we-do-One.svg')
                      "
                      alt="we help you"
                    />
                  </div>
                  <div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/key.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this works
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          We give you access to over 300 situational and
                          emotional based assessments designed by clinical
                          mental health experts tailored to specific life
                          events, experiences and feelings that impact your
                          wellbeing.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/paper.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          Why this is important
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          Just like a doctor asks questions to assess your
                          physical health, we ask questions to assess and help
                          you better understand the state of your mental health.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/users.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this helps you
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          We help you understand yourself and why you're feeling
                          and thinking in certain ways. We help you measure the
                          impact of day to day and key life events, situations,
                          and emotions on your wellbeing.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>

          <section class="py-5 light-blue-bg">
            <article class="container px-lg-4">
              <div class="row">
                <div class="col-12 col-xl-6">
                  <div
                    class="
                      d-flex
                      justify-content-center justify-content-xl-start
                    "
                  >
                    <div
                      class="
                        d-inline-flex
                        justify-content-center
                        align-items-center
                        rounded-circle
                        deep-blue-bg
                        circle-with-number
                        mb-2
                        align-self-center
                      "
                    >
                      <span class="font-size-24 lato-bold text-white">2.</span>
                    </div>
                  </div>
                  <h2
                    class="
                      title-text-pink
                      pb-2 pb-xl-5
                      text-center text-xl-left
                      font-size-30
                    "
                  >
                    We help you better monitor your mental health and wellness
                  </h2>
                  <div class="d-flex d-xl-none justify-content-center">
                    <b-img
                      class="max-h-350"
                      fluid
                      :src="
                        require('@/assets/images/pages/what-we-do/What-we-do-Two.svg')
                      "
                      alt="we help you"
                    />
                  </div>
                  <div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/key.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this works
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          By having regular daily, weekly and monthly mental
                          health checks, it becomes easier for you to visualise
                          and track your mental health progress, patterns and
                          trends over time.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/paper.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          Why this is important
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          We all have different life experiences that impact our
                          emotions and mental health. We shine a light on the
                          combined impact of these experiences on your mental
                          health.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/users.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this helps you
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          Mental health can be complex. Mental health check
                          provides you with personalised information in real
                          time, so you feel empowered to make better, more
                          informed decisions and adjustments when you need to.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 d-none d-xl-flex">
                  <b-img
                    fluid
                    :src="
                      require('@/assets/images/pages/what-we-do/What-we-do-Two.svg')
                    "
                    alt="we help you"
                  />
                </div>
              </div>
            </article>
          </section>

          <section class="py-5">
            <article class="container px-lg-4">
              <div class="row">
                <div class="col-6 d-none d-xl-flex">
                  <b-img
                    fluid
                    :src="
                      require('@/assets/images/pages/what-we-do/What-we-do-Three.svg')
                    "
                    alt="we help you"
                  />
                </div>
                <div class="col-12 col-xl-6">
                  <div
                    class="
                      d-flex
                      justify-content-center justify-content-xl-start
                    "
                  >
                    <div
                      class="
                        d-inline-flex
                        justify-content-center
                        align-items-center
                        rounded-circle
                        deep-blue-bg
                        circle-with-number
                        mb-2
                        align-self-center
                      "
                    >
                      <span class="font-size-24 lato-bold text-white">3.</span>
                    </div>
                  </div>
                  <h2
                    class="
                      title-text-blue
                      pb-2 pb-xl-5
                      text-center text-xl-left
                      font-size-30
                    "
                  >
                    We help you consistently improve your mental health and
                    wellness
                  </h2>
                  <div class="d-flex d-xl-none justify-content-center">
                    <b-img
                      class="max-h-350"
                      fluid
                      :src="
                        require('@/assets/images/pages/what-we-do/What-we-do-Three.svg')
                      "
                      alt="we help you"
                    />
                  </div>
                  <div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/key.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this works
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          You receive guidance from mental health experts
                          specific to the experiences and emotions that impact
                          your well-being using clinically backed evidence-based
                          theories, practices and techniques.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/paper.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          Why this is important
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          Regular practice helps you control your thoughts,
                          actions and reactions to your feeling’s life
                          situations and events. Learn to make your feelings
                          work to increase your well-being regardless of the
                          situation.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/users.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this helps you
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          Mental health check helps you improve how you think,
                          feel and react to life situations and events. We guide
                          you on how to make your feelings work to increase your
                          well-being anytime, anywhere.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>

          <section class="py-5 light-blue-bg">
            <article class="container px-lg-4">
              <div class="row">
                <div class="col-12 col-xl-6">
                  <div
                    class="
                      d-flex
                      justify-content-center justify-content-xl-start
                    "
                  >
                    <div
                      class="
                        d-inline-flex
                        justify-content-center
                        align-items-center
                        rounded-circle
                        deep-blue-bg
                        circle-with-number
                        mb-2
                        align-self-center
                      "
                    >
                      <span class="font-size-24 lato-bold text-white">4.</span>
                    </div>
                  </div>
                  <h2
                    class="
                      title-text-pink
                      pb-2 pb-xl-5
                      text-center text-xl-left
                      font-size-30
                    "
                  >
                    We help you effectively maintain good mental health and
                    wellness
                  </h2>
                  <div class="d-flex d-xl-none justify-content-center">
                    <b-img
                      class="max-h-350"
                      fluid
                      :src="
                        require('@/assets/images/pages/what-we-do/What-we-do-Four.svg')
                      "
                      alt="we help you"
                    />
                  </div>
                  <div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/key.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this works
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          You get immediate access to your learn and develop
                          personalised library full of evidence-based exercises
                          and therapies that ensure you feel empowered,
                          supported and in control of your mental health.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/paper.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          Why this is important
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          Life today is mentally challenging and getting the
                          mental health support you want when you want it, can
                          prove stressful. Mental health check is here to
                          support you anytime you need it, anywhere you're
                          digitally connected.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/users.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this helps you
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          We promise to provide access to mental health experts
                          and their clinically backed practices, techniques and
                          therapies proven to increase the feeling of calm and
                          having better emotional balance and control.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-6 d-none d-xl-flex">
                  <b-img
                    fluid
                    :src="
                      require('@/assets/images/pages/what-we-do/What-we-do-Four.svg')
                    "
                    alt="we help you"
                  />
                </div>
              </div>
            </article>
          </section>

          <section class="py-5">
            <article class="container px-lg-4">
              <div class="row">
                <div class="col-6 d-none d-xl-flex">
                  <b-img
                    fluid
                    :src="
                      require('@/assets/images/pages/what-we-do/What-we-do-Five.svg')
                    "
                    alt="we help you"
                  />
                </div>
                <div class="col-12 col-xl-6">
                  <div
                    class="
                      d-flex
                      justify-content-center justify-content-xl-start
                    "
                  >
                    <div
                      class="
                        d-inline-flex
                        justify-content-center
                        align-items-center
                        rounded-circle
                        deep-blue-bg
                        circle-with-number
                        mb-2
                        align-self-center
                      "
                    >
                      <span class="font-size-24 lato-bold text-white">5.</span>
                    </div>
                  </div>
                  <h2
                    class="
                      title-text-blue
                      pb-2 pb-xl-5
                      text-center text-xl-left
                      font-size-30
                    "
                  >
                    We help you connect to others with similar experiences
                  </h2>
                  <div class="d-flex d-xl-none justify-content-center">
                    <b-img
                      class="max-h-350"
                      fluid
                      :src="
                        require('@/assets/images/pages/what-we-do/What-we-do-Five.svg')
                      "
                      alt="we help you"
                    />
                  </div>
                  <div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/key.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this works
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          Each completed mental health check anonymously
                          connects you to a community where you can share your
                          thoughts and feelings with others that have had
                          similar experiences, understand and want to help.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left mb-2">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/paper.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          Why this is important
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          You may feel alone with your thoughts, your struggles
                          and needing support, but this doesn't have to be your
                          reality. Mental health check provides a safe place to
                          talk without any stigma.
                        </p>
                      </div>
                    </div>
                    <div class="d-lg-flex text-center text-lg-left">
                      <div class="pb-2 pb-lg-0 pr-lg-2">
                        <b-img
                          class="min-w-40px"
                          fluid
                          :src="require('@/assets/images/icons/users.png')"
                          alt="we help you"
                        />
                      </div>
                      <div>
                        <h5 class="font-size-20 text-dark-gray lato-bold">
                          How this helps you
                        </h5>
                        <p class="font-size-20 text-dark-gray line-h-1-5">
                          We help you feel connected and supported 24/7,
                          anytime, anywhere. No more feeling isolated and
                          misunderstood. Staying anonymous means you can give
                          and receive support without judgement.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </section>

          <!-- content end -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EventBus from "@/main.js";
import { BLink, BModal, BButton, BTable, BImg } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BTable,
    BImg,
  },
  props: {
    size: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    openModal() {
      if (this.$refs["what-we-do-modal"] != undefined) {
        // Show modal
        this.$refs["what-we-do-modal"].show();
      }
    },
  },
  mounted() {
    EventBus.$on("openWhatWeDo", () => {
      this.openModal();
    });
  },
};
</script>

<style scoped>
p {
  font-weight: normal;
}

ol {
  padding-left: 1rem;
}

li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.circle-with-number {
  width: 60px;
  height: 60px;
}

.title-text-blue {
  color: #318ba2;
}
.title-text-pink {
  color: #d45ecf;
}

.line-h-1-5 {
  line-height: 1.5;
}

.max-h-350 {
  max-height: 350px;
}

.min-w-40px {
  min-width: 40px;
}

@media (max-width: 992px) {
  .font-size-36 {
    font-size: 28px;
  }
}
</style>