<template>
  <div>
    <b-link @click="openModal" id="openModalId" v-show="show">
      <div class="text-center pb-2 vertical-link-style">
        <feather-icon icon="UserPlusIcon" class="vertical-link-style-icon" />
        <span> Invite friends</span>
      </div>
    </b-link>

    <b-modal
      id="invite-friends-modal"
      ref="invite-friends-modal"
      hide-footer
      centered
      size="lg"
    >
      <validation-observer ref="inviteRules">
        <div class="d-block py-2 px-5 invite-friends-modal">
          <div class="text-center">
            <h3>Invite friends & family</h3>
            <p>
              Easily invite your friends and family to start their mental health
              checks using email, facebook or WhatsApp.
            </p>
            <hr class="mhc-divider m-auto" />
          </div>
          <div class="two-share-buttons py-2">
            <b-button style="padding: 1rem">
              <b-link
                class="mhc-link-no-color"
                href="#"
                @click="openShareDialog('facebook', 'https://www.facebook.com/sharer/sharer.php?u=https://app.mentalhealthcheck.org/')"
                target="_blank"
              >
                <img src="/fb.png" alt="fb icon" />
                <span class="font-size-14"> Share via Facebook</span>
              </b-link>
            </b-button>

            <b-button style="padding: 1rem">
              <b-link
                class="mhc-link-no-color"
                href="#"
                @click="openShareDialog('whatsapp', 'https://api.whatsapp.com/send/?phone&text=I+signed+up+to+mentalhealthcheck.org+to+better+understand,+track+and+improve+my+mental+health+in+a+safe+and+anonymous+way.+Your+mental+health+is+important+and+I+think+you+should+sign+up+too.+Find+out+more+at+%E2%80%93+https://app.mentalhealthcheck.org/')"
                target="_blank"
              >
                <img src="/wa.png" alt="wa icon" />
                <span class="font-size-14"> Share via WhatsApp</span>
              </b-link>
            </b-button>
          </div>
          <div class="text-center">
            <p>Complete the form below to send email invites.</p>

            <b-form-group
              label="Full Name"
              label-for="firstname"
              class="text-left"
            >
              <validation-provider
                #default="{ errors }"
                name="Full name"
                rules="required"
              >
                <b-form-input
                  id="firstname"
                  v-model="firstname"
                  name="firstname"
                  type="text"
                  placeholder="Full Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Email Address"
              label-for="email"
              class="text-left"
            >
              <validation-provider
                #default="{ errors }"
                name="Email Address"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="email"
                  name="email"
                  type="email"
                  placeholder="Email Address"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Select Relation"
              label-for="relation"
              class="text-left"
            >
              <b-form-select
                v-model="selectedRelation"
                :options="optionsRelation"
              />
            </b-form-group>

            <b-form-group label="Message" label-for="message" class="text-left">
              <validation-provider
                #default="{ errors }"
                name="Message"
                rules="required"
              >
                <b-form-textarea
                  v-model="message"
                  name="message"
                  placeholder="Message"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="text-center">
            <p>
              Choose to support your family & friends by following their mental
              health progress. They can choose to follow and support you too.
            </p>

            <div
              class="
                d-flex
                justify-content-between
                follow-no-folllow-container
                flex-column flex-sm-row
              "
            >
              <div @click="checkInput(1)">
                <b-form-radio v-model="do_follow" value="1">
                  Do follow
                </b-form-radio>
              </div>
              <div class="mt-1 mt-sm-0" @click="checkInput(0)">
                <b-form-radio v-model="do_follow" value="0">
                  No follow
                </b-form-radio>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <div></div>
              <div>
                <small class="cursor-pointer" v-b-tooltip="tooltipOptions"
                  >What's this</small
                >
              </div>
            </div>
          </div>

          <div class="text-center">
            <b-button
              variant="primary"
              :disabled="isDisabled"
              class="mt-2 w-75 py-1"
              @click="sendInvite"
              >{{ buttonMessage }}</b-button
            >
          </div>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import EventBus from "../../main.js";

import {
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BFormTextarea,
  BFormRadio,
  BButton,
  BLink,
  BForm,
  BRow,
  BCol,
  BInputGroup,
  VBTooltip,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BFormInput,
    BFormSelect,
    BModal,
    BFormRadio,
    BButton,
    BFormTextarea,
    BLink,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BInputGroup,
  },
  directives: { "b-tooltip": VBTooltip },
  data() {
    return {
      required,

      firstname: "",
      email: "",
      selectedRelation: 1,
      relations: {},
      optionsRelation: [],
      message: "",
      do_follow: 1,
      disabled: true,
      buttonMessage: "SHARE VIA EMAIL",
      tooltipOptions: {
        title:
          "The option to 'Do follow' means that you are requesting to be alerted about their mental health score so you can check in and encourage them when their mental health is low but also celebrate and show support when their mental health is good. Even better, they are able to do the same for you too. <br><br> The option to 'No follow' means that you do not want to be alerted about their mental health progress. This means that you are not able to check in and encourage them when their mental health is low or celebrate and show support when their mental health is good. They will not be able to do the same for you either.",
        html: true,
      },
    };
  },
  props: {
      show: {
          type: Boolean
      }
  },
  computed: {
    // Make sure all required fields are filled out
    isDisabled() {
      if (
        this.firstname.length > 0 &&
        this.email.length > 0 &&
        this.message.length > 0
      ) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      return this.disabled;
    },
  },
  methods: {
    openShareDialog(type, link) {
      this.$http
        .post("/api/auth/user/invite/sent", {
          "type": type
        })
        .then((res) => {

        })    
      window.open(link, '_blank').focus();
    },
    openModal() {
      if (this.$route.name == "learn-and-develop-single") {
        this.message =
          "Mental health is important and I think you should join me. Find out more at – https://app.mentalhealthcheck.org/";
      } else {
        this.message =
          "I signed up to mentalhealthcheck.org to better understand, track and improve my mental health in a safe and anonymous way. Your mental health is important and I think you should sign up too. Find out more at – https://app.mentalhealthcheck.org/";
      }

      if (this.$refs["invite-friends-modal"] != undefined) {
        // Show modal
        this.$refs["invite-friends-modal"].show();
      }
    },
    closeModal() {
      this.$refs["invite-friends-modal"].hide();
    },
    checkInput(value) {
      // Set value from Do follow (1)/No follow (0)
      this.do_follow = value;
    },
    sendInvite() {
      // Disable button and change his text
      this.disabled = true;
      this.buttonMessage = "SENDING INVITE...";

      this.$refs.inviteRules.validate().then((success) => {
        // Validation
        if (success) {
          // Request Send invite
          this.$http
            .post("/api/auth/user/invite", {
              name: this.firstname,
              email: this.email,
              user_relation_id: this.selectedRelation,
              message: this.message,
              do_follow: this.do_follow,
            })
            .then((res) => {
              if(res != undefined) {
                // Show message
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "CheckSquareIcon",
                    variant: "success",
                  },
                });

                // Set button text
                this.buttonMessage = "SHARE VIA EMAIL";

                // Close modal if request is completed
                this.closeModal();

                // Reset form
                this.firstname = "";
                this.email = "";
                this.selectedRelation = 1;

                // Set data message
                if (this.$route.name == "learn-and-develop-single") {
                  this.message =
                    "Mental health is important and I think you should join me. Find out more at – https://app.mentalhealthcheck.org/";
                } else {
                  this.message =
                    "I signed up to mentalhealthcheck.org to better understand, track and improve my mental health in a safe and anonymous way. Your mental health is important and I think you should sign up too. Find out more at – https://app.mentalhealthcheck.org/";
                }

                this.do_follow = 1;

                // Get fresh invites sent number
                EventBus.$emit("freshInvitesSentNumber");
              }
            })
            .catch((err) => {
              console.log(err);

              // Show message
              let errMsg = "";
              if (
                err.response.data.errors != null &&
                err.response.data.errors != undefined
              ) {
                errMsg = err.response.data.errors.email[0];
              } else {
                errMsg = "Something went wrong";
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errMsg,
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });

              this.buttonMessage = "SHARE VIA EMAIL";
            });
        } else {
          this.disabled = true;
          this.buttonMessage = "SHARE VIA EMAIL";
        }
      });
    },
  },
  mounted() {
    // Set data message
    if (this.$route.name == "learn-and-develop-single") {
      this.message =
        "Mental health is important and I think you should join me. Find out more at – https://app.mentalhealthcheck.org/";
    } else {
      this.message =
        "I signed up to mentalhealthcheck.org to better understand, track and improve my mental health in a safe and anonymous way. Your mental health is important and I think you should sign up too. Find out more at – https://app.mentalhealthcheck.org/";
    }

    // Open modal to invite friends
    EventBus.$on("inviteFriends", () => {
      this.openModal();
    });

    // Request to get all realtions from db
    if (localStorage.getItem("userData") != null) {
      this.$http
      .get("/api/auth/relations")
      .then((res) => {
        if(res != undefined) {
          let relations = [];

          // Prepare data for relation select box
          for (let i = 0; i < res.data.length; i++) {
            relations.push({
              value: res.data[i].id,
              text: res.data[i].name,
            });
          }

          // Fill relation select box
          this.optionsRelation = relations;
        }
      })
      .catch((err) => {
        console.log(err);

        // Show toast
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
        return;
      });
    }
    
  },
};
</script>

<style scoped>
.vertical-link-style {
  font-size: 1.1rem;
  font-family: "Montserrat", Helvetica, Arial, serif;
  font-weight: 400;

  display: flex;
  align-items: center;

  transition: transform 0.25s ease;

  margin: 0 15px;
  padding: 10px 15px 10px 15px;

  color: #625f6e;
  line-height: 1.45;
}

.vertical-link-style:hover{
  transform: translateX(1.1rem);
}

.vertical-link-style-icon {
  margin-right: 1.1rem;
}

@import "../../assets/css/modals-style.css";
</style>
