<template>
  <section class="pb-4">
    <b-modal
      id="help-modal"
      centered
      scrollable 
      ok-only
      hide-footer
      size="lg"
      ref="help-modal">
      <div class="d-block py-2 px-5">
        <div class="text-center">
          <h3 class="pb-1">Welcome to Daily Check-in</h3>
          <p class="font-weight-normal">
            Get started by selecting the option you feel has the greatest impact
            on your mental health.
          </p>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="daily-check-in-modal"
      centered
      scrollable 
      ok-only
      hide-footer
      :no-close-on-backdrop="true"
      size="lg"
      @hide="resetDailyCheckInData()"
      ref="daily-check-in-modal">
      <div class="bg-white">
        <b-col lg="12" xl="12">
          <b-row class="justify-content-between no-gutters m-auto mb-2" style="max-width: 90%">            

            <b-col md="12" class="mhc-header-title">
              
              <b-link class="header-pagination position-absolute" v-on:click="prev" v-if="step > 1 && progressStep != 7">
                <feather-icon icon="ArrowLeftIcon" size="18" />
                Previous
              </b-link>

              <b-row class="no-gutters">
                <h3 class="m-auto color-mhc-dark">
                  Daily Check-in - {{ today }}
                </h3>
              </b-row>

              <b-row class="no-gutters">
                <p class="m-auto text-center">
                  You are free to stop whenever you choose
                </p>
              </b-row>

              <b-link class="position-absolute" style="right: 0; top: 0" v-on:click="helpDialog()">
                Help
              </b-link>

            </b-col>
          </b-row>
          <b-row class="justify-content-between no-gutters m-auto mb-2" style="max-width: 90%">
            <div class="w-100">
              <b-progress
                :value="progressStep"
                max="7"
                min="1"
                variant="mhc-dark"
                height=".5rem"
                width="100%"
                class="mt-2 mb-1 questionnaire-progress"
              />
            </div>
          </b-row>
          <div class="questionBox d-flex align-items-center justify-content-center" v-if="paymentRequired == true">
            <div class="text-center">
              <h3 class="font-weight-normal">Upgrade for full access</h3>
              <p class="mt-1 font-weight-normal">From £1</p>
              <b-button @click="tellMeMore" variant="primary" class="mt-1">Tell me more</b-button>
            </div>
          </div>
          <div class="questionBox py-3" v-if="paymentRequired == false && step >= 1 && step <= 5">            
            <b-overlay :show="show" rounded="sm" class="w-100">
              <a href="#" @click="viewTodaysAnswers()" v-if="hasCompletedDailyCheckIn && step == 1">
                <u>
                  View Today's answers
                </u>
              </a>
              <daily-check-in-options v-if="step == 1" headingText="How are you doing today?" :options="options" :step="step" :option="selectedMood.name" :key="key" />
              <daily-check-in-options v-if="step == 2" :headingText="'Based on you doing ' + selectedMood.name.toLowerCase() + ' today, what is the strongest emotion you feel?'" :options="emotions" :option="selectedEmotion.name" :key="key" :step="step" />
              <daily-check-in-options v-if="step == 3" :headingText="'Based on you feeling ' + selectedEmotion.name.toLowerCase() + ' today, what is the strongest emotion you feel?'" :options="subEmotions" :option="selectedSubEmotion.name" :key="key" :step="step" />
              <daily-check-in-options v-if="step == 4" :headingText="'Based on you feeling ' + selectedSubEmotion.name.toLowerCase() + ' today, what has made you feel this way?'" :options="questionnaireCategories" :option="questionnaireCategory.name" :key="key" :step="step" />
              <daily-check-in-options v-if="step == 4.5" headingText="Select an option that best matches your situation" :options="questionnaireSubCategories" :option="questionnaireSubCategory.name" :key="key" :step="step" />
              <daily-check-in-options v-if="step == 5 && hasCarrerOptionChosen == false" :headingText="'Select the ' + questionnaireCategory.name + ' that made you feel ' + selectedEmotion.name.toLowerCase()" :options="questionnaireSubCategories" :option="questionnaireName" :key="key" :step="step" />
              <daily-check-in-options v-if="step == 5 && hasCarrerOptionChosen == true" :headingText="'Select the job for you being ' + questionnaireCategory.name.toLowerCase() + ' that made you feel ' + selectedEmotion.name.toLowerCase()" :options="questionnaireSubCategories" :option="questionnaireName" :key="key" :step="step" />
            </b-overlay>
          </div>
          <div v-if="step == 6">
            <div class="mb-2">
              <h3>My Journal - Daily Check-in</h3>

              <p>Date: {{ today }}</p>
              
              <p>How you’re doing today: {{ selectedMood.name }}<br>
              Strongest emotion today: {{ selectedEmotion.name }}<br>
              Specific {{ selectedEmotion.name }}  emotion: {{ selectedSubEmotion.name }}<br>
              Reason for feeling specific emotion: {{ questionnaireCategory.name }}<br>
              More specific reason: {{ questionnaireName }}</p>
            </div>
            <h3><strong>Add your thoughts about today</strong></h3>
            <div style="min-height: 300px" class="mt-1">
              <quill-editor
                v-model="thoughtsAboutToday"
                style="height: 200px"
              />
            </div>

            <div class="d-flex justify-content-end mb-2">
              <b-button variant="primary" @click="step++; progressStep++">Continue</b-button>
            </div>

          </div>
          <div v-if="step == 7">
            <b-overlay :show="show" rounded="sm" class="w-100">
              <div class="mb-2">
                <h3>My Journal - {{ journalText.title }}</h3>

                <p>
                  Date: {{ today }}<br>
                  Time: {{ now }}
                </p>
                
                <p v-html="journalText.text"></p>
              </div>
              
              <div style="min-height: 300px" class="mt-1">
                <quill-editor
                  v-model="journalThoughts"
                  style="height: 200px"
                />
              </div>

              <div class="d-flex justify-content-end mb-2">
                <b-button variant="primary" @click="addToJournal()">
                  Continue
                </b-button>
              </div>
            </b-overlay>
          </div>
          <div  v-if="step == 8" style="min-height: 300px;" class="d-flex align-items-center justify-content-center">
            <div style="width: 100%;">
              <h3 class="text-center"> Well done! </h3>
              <h4 style="text-align: center;"> 
                Your have completed your daily check-in
              </h4>
              <div class="d-flex justify-content-around mt-3">
                <button type="button" class="btn btn-primary" @click="viewDailyCheckInsProgress()">View Progress</button>
                <button type="button" class="btn btn-primary" @click="viewRecommendedToolkits()">View Recommendations</button>
              </div>
            </div>
          </div>
        </b-col>
      </div>
    </b-modal>
  </section>
</template>

<script>
import store from "@/store/index";
import { BRow, BCol, BOverlay, BButton, BModal, BFormSelect, BProgress, BLink } from "bootstrap-vue";
import EventBus from '../../../main.js';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DailyCheckInOptions from "./DailyCheckInOptions.vue"
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BButton,
    BModal,
    BFormSelect,
    ToastificationContent,
    DailyCheckInOptions,
    BProgress,
    BLink,
    quillEditor
  },
  data() {
    return {
      options: [],
      selectedMood: {
        name: ""
      },
      emotions: [],
      selectedEmotion: {
        name: ""
      },
      selectedSubEmotion: {
        name: ""
      },
      questionnaireCategories: [],
      questionnaireSubCategories: [],
      questionnaireCategory: {
        name: ""
      },
      questionnaires: [],
      step: 1,
      progressStep: 0,
      key: 0,
      questionnairesApiToBeCalled: false,
      show: false,
      journalStep1: true,
      journalStep2: false,
      journalStep3: false,
      journalStep4: false,
      questionnaireSubCategory: {
        name: ""
      },
      questionnaireName: "",
      today: "",
      now: "",
      thoughtsAboutToday: "",
      journalThoughts: "",
      journalText: {
        "title": "",
        "text": ""        
      },
      hasCompletedDailyCheckIn: false,
      hasCarrerOptionChosen: false,
      paymentRequired: false
    }
  },
  methods: {
    prev() {

      this.progressStep--;
      if(this.step == 5 && this.hasCarrerOptionChosen) {
        this.questionnairesApiToBeCalled = false;
        this.getQuestionnaireSubcategories();
        this.step = 4.5;    
        this.questionnairesApiToBeCalled = true;
      } else if(this.step == 4.5) {
        this.step = 4;
      } else {
        this.step--;
      }
      this.key++;
    },
    viewDailyCheckInsProgress() {
      this.$refs['daily-check-in-modal'].hide();
      sessionStorage.setItem("goToDailyCheckInTab", true);
      this.$router.push("/my-hub");
      EventBus.$emit("goToDailyCheckInTab")
    },
    resetDailyCheckInData() {
      this.step = 1; 
      this.questionnairesApiToBeCalled = false;
      this.questionnaireCategories = [];
      this.questionnaireSubCategories = [];
      this.questionnaires = [];
      this.journalStep1 = true;
      this.journalStep2 = false;
      this.journalStep3 = false;
      this.journalStep4 = false;
      this.journalThoughts = "";
      this.thoughtsAboutToday = "";
      this.questionnaireSubCategory = {
        name: ""
      };
      this.progressStep = 0;
    },
    selectMood(id) {
      if(this.options != undefined) {
        this.options.forEach((element) => {
          if(element.id == id) {
            this.selectedMood = element
            this.emotions = element.user_emotions
            this.step++;
          }
        })
      }      
    },
    selectEmotion(id) {
      if(this.emotions != undefined) {
        this.emotions.forEach((element) => {
          if(element.id == id) {
            this.selectedEmotion = element
            this.subEmotions = element.user_sub_emotions
            this.step++;
          }
        })
      }
    },
    selectSubEmotion(id) {
      if(this.subEmotions != undefined) {
        this.subEmotions.forEach((element) => {
          if(element.id == id) {
            this.selectedSubEmotion = element
          }
        })
        this.getQuestionaireCategories();
      }      
    },
    selectQuestionnaireCategory(questionnaireCategory) {
      this.questionnaireCategory = questionnaireCategory;
      if(this.questionnaireCategory.name.includes("job/career")) {
        this.step = 4.5;
        this.hasCarrerOptionChosen = true;
      } else {
        this.hasCarrerOptionChosen = false;
        this.step = 5;     
      }
      this.getQuestionnaireSubcategories();
    },
    getQuestionnaireSubcategories() {

      let apiToBeCalled = "/api/questionnaire-subcategories/" + this.questionnaireCategory.id + "?available=1";

      if(this.questionnairesApiToBeCalled == true) {
        apiToBeCalled = "/api/subcategory-questionnaires/" + this.questionnaireSubCategory.id
      }
      this.show = true;

      this.$http.get(apiToBeCalled)
      .then((res) => {
        if(res != undefined) {
          this.questionnaireSubCategories = res.data
          this.show = false;          
        }
      })
      .catch((error) => {
        console.log(error);
        this.show = false;          

        // Hide overlay
        this.filterOverlay = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });    
    },
    getQuestionaireCategories() {
      this.show = true;      
      this.$http.get("/api/questionnaire-category?name=feel") // dont show the feeling (emotions) category
        .then((res) => {
          this.questionnaireCategories = res.data
          this.step++;
          this.show = false;          
        })
        .catch((err) => {
          console.log(err);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    getRandomJournalText() {
      this.$http.get("/api/auth/random-journal-text")
        .then((res) => {
          this.journalText = res.data;     
        })
        .catch((err) => {
          console.log(err);

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },
    addToJournal() {
      this.show = true;

      let subSpecificReason = "";
      if(this.hasCarrerOptionChosen == true) {
        subSpecificReason = this.questionnaireSubCategory.name;
      }

      this.$http.post("/api/auth/user-daily-check-in", {
        "user_sub_emotion_id": this.selectedSubEmotion.id,
        "reason": this.questionnaireCategory.name,
        "more_specific_reason": this.questionnaireName,
        "sub_specific_reason": subSpecificReason,
        "user_thoughts": this.thoughtsAboutToday,
        "journal_text_id": this.journalText.id,
        "user_journal_thoughts": this.journalThoughts,
        "user_mood_id": this.selectedMood.id
      })
      .then((res) => {        
        this.journalStep3 = false;
        this.journalStep4 = true; 
        this.show = false;
        this.progressStep++;
        this.step++;
        EventBus.$emit("dailyCheckInCompleted")
      })
      .catch((err) => {
        this.show = false;
        console.log(err);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },
    getCurrentTime() {
      this.$http.get("/api/auth/current-time")
        .then((res) => {
          this.today = res.data.date;
          this.now = res.data.time
        })
        .catch((err) => {

        });
    },
    viewTodaysAnswers() {
      this.$refs['daily-check-in-modal'].hide();
      EventBus.$emit("goToDailyCheckInTab");
    },
    tellMeMore() {
      this.$refs['daily-check-in-modal'].hide();
      this.$router.push("/change-subscription-plan");      
    },
    viewRecommendedToolkits() {
      this.$refs['daily-check-in-modal'].hide();
      if(this.$router.currentRoute.path == "/learn-and-develop/view/all") {
        this.$router.replace({ path: '/learn-and-develop/view/all', query: { emotion: this.selectedEmotion.id } })
        EventBus.$emit("resetToolkitList")
      } else {
        this.$router.replace({ path: '/learn-and-develop/view/all', query: { emotion: this.selectedEmotion.id } })
      }
    },
    helpDialog() {
      this.$refs['help-modal'].show();
    }
  },  
  created() {

    this.getRandomJournalText();
    this.getCurrentTime();

    EventBus.$on("dailyCheckInOptionSelected", (data) => {
      
      if(data.step == 1) {
        this.selectMood(data.option.id);
      } else if(data.step == 2) {
        this.selectEmotion(data.option.id)
      } else if(data.step == 3) {
        this.selectSubEmotion(data.option.id)
      } else if(data.step == 4) {
        this.questionnairesApiToBeCalled = false;
        this.selectQuestionnaireCategory(data.option)
      } else if(data.step == 4.5) {
        this.questionnaireSubCategory = data.option
        this.questionnairesApiToBeCalled = true;
        this.hasCarrerOptionChosen = true;
        this.step = 5
        this.getQuestionnaireSubcategories();
        //this.selectQuestionnaireCategory(data.option)
      } else if(data.step == 5) {
        if(data.option.name != undefined) {
          this.questionnaireName = data.option.name
        } else {
          this.questionnaireName = data.option.title
        }
        this.step++;
      }
      this.progressStep++;
    });

    EventBus.$on('myDailyCheckIn', () => {            
      // had to make sure to send only one request at a time
      // if you remove this check, multiple requests will be sent at the same time
      // this happens because for some reason the component <daily-check-in/> is instantiated multiple times
      // when user for example switches between my hub page and the "start new questionnaire" page

      if(sessionStorage.getItem("dcirqs") == 1) { 
        return false;
      }

      if(this.$refs['daily-check-in-modal'] != undefined) {
        this.$refs['daily-check-in-modal'].show();
      }
      this.show = true;

      //sessionStorage.setItem("dcirqs", 1);      
        this.$http.get("/api/auth/payment/plan-price")
        .then((res) => {
          // if the plan price is not 0, that means that the user is subscribed to the premium plan
          if(res.data.price != 0) {
            if(this.$refs['daily-check-in-modal'] != undefined) {
              this.$http.get("/api/auth/user-moods")
                .then((res) => {
                  this.options = res.data
                  sessionStorage.setItem("dcirqs", 0);

                  this.$http.get("/api/auth/has-completed-daily-check-in")
                  .then((res) => {

                    if(res.data != "") {
                      this.selectedMood = res.data.user_mood;
                      this.selectedSubEmotion = res.data.user_sub_emotions;
                      this.selectedEmotion = res.data.user_sub_emotions.user_emotion;
                      this.thoughtsAboutToday = res.data.user_thoughts;
                      this.journalThoughts = res.data.user_journal_thoughts;
                      
                      this.questionnaireCategory.name = res.data.reason;
                      if(res.data.sub_specific_reason != null && res.data.sub_specific_reason != "") {
                        this.hasCarrerOptionChosen = true;
                      } else {
                        this.hasCarrerOptionChosen = false;
                      }
                      this.questionnaireSubCategory.name = res.data.sub_specific_reason;
                      
                      this.questionnaireName = res.data.more_specific_reason;

                      this.hasCompletedDailyCheckIn = true
                      this.show = false;
                    } else {
                      this.hasCompletedDailyCheckIn = false
                      this.show = false;
                    }
                  }).catch((err) => {
                    this.show = false;
                  })

                })
                .catch((err) => {
                  sessionStorage.setItem("dcirqs", 0);
                  if(err.response.status == 406) {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: err.response.data.message,
                        icon: "AlertCircleIcon",
                        variant: "info",
                      },
                    });
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: "Something went wrong. Please try again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                      },
                    });
                  }
                });
            }
          } else {
            this.paymentRequired = true;
          }
        })
        .catch((err) => {
            console.log(err);
        })
    });
  }  
}
</script>

<style lang="scss">
@import "../../../assets/css/style.css";
@import '@core/scss/vue/libs/quill.scss';

.fix-height-for-cols {
  margin-bottom: 2rem!important;
  height: 100%!important;
}

.fix-height-for-cols .card {
  height: 100%;
}

</style>
