<template>
  <div class="p-0">
    <b-link @click="openModal" v-show="show">
      <small v-if="size == 'sm'">Our Approach</small>
      <div v-else>Our Approach</div>
    </b-link>

    <b-modal
      id="science-modal"
      ref="science-modal"
      hide-footer
      centered
      size="xl"
    >
      <template #modal-title>
        Our Approach behind Mental Health Check
      </template>
      <div class="d-block">
        <div class="w-100">
          <!-- content start -->
          <section class="deep-blue-bg py-5">
            <h1 class="container px-lg-4 text-center text-white font-size-36">
              At MentalHealthCheck, our approach to supporting your mental
              health is founded on the following ….
            </h1>
          </section>

          <section class="py-3">
            <article class="container-1020 px-lg-4 text-center">
              <div class="pb-2">
                <b-img
                  fluid
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                The Biopsychosocial Model
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Widely accepted by mental health professionals, the
                biopsychosocial model suggests that our mental health is a
                combination of biological, psychological and social influences.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Some examples include …
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                - Biological influences: Physical health, hormones, stress
                response, drugs (medication/recreation), ethnicity, genetic
                tendencies amongst many others <br />
                - Psychological influences: Memories, emotions, temperament,
                beliefs, coping mechanisms, personality traits amongst many
                others. <br />
                - Social influences: Location, culture, personal experiences,
                work-life, social groups, family relationships amongst many
                others.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                As human beings, we are a combination of many variables that
                clearly indicate just how complicated or mental experiences can
                be and complex to address.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Managing your mental health is a journey, not a destination and
                the biopsychosocial model helps you start to unpick and better
                understand these complexities
              </p>
            </article>
          </section>

          <section class="py-3 light-blue-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                Cognitive Behavioural Therapy (CBT)
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Cognitive Behaviour Therapy (CBT) is founded on the idea that
                our thoughts and feelings drive our behaviours with a focus on
                addressing issues we may be facing or dealing with in the
                present.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                This therapy approach uses problem-solving to address and change
                how we think, changing how we react to challenging situations.
              </p>
            </article>
          </section>

          <section class="py-3 very-light-gray-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                Dialectical Behaviour Therapy (DBT)
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Dialectical Behaviour Therapy (DBT) is focused on helping you
                develop the skills to better manage painful emotions and
                challenging relationships using four key methods:
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                - Mindfulness: Helping you accept and be present in the moment.
                <br />
                - Distress tolerance: Helping you better deal with negative
                emotions rather than run away from them. <br />
                - Emotion regulation: Helping you manage and change emotions
                that may be disrupting your life and relationships. <br />
                - Interpersonal effectiveness: Helping communicate assertively
                while retaining self-respect and building stronger
                relationships.
              </p>
            </article>
          </section>

          <section class="py-3 pink-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                Acceptance and Commitment Therapy (ACT)
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Acceptance and Commitment Therapy (ACT) is focused on helping us
                accept our inner emotions and feelings as the right feelings to
                have based on situations we may experience.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                The emphasis is on not avoiding, denying or struggling with
                these emotions but rather accepting them and minimising how they
                affect everyday life.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                With this in mind, ACT helps us accept life’s issues and
                challenges, so we commit to making the behavioural changes
                necessary regardless of how we feel or what we are going
                through.
              </p>
            </article>
          </section>

          <section class="py-3 light-blue-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                Compassion Focussed Therapy (CFT)
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Compassion Focussed Therapy (CFT) is focused on helping you be
                more compassionate to yourself and others so you accept yourself
                for who you are while enjoying more feelings of safety and
                comfort.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                This is especially relevant if you’re overly critical and hard
                on yourself or experience the feeling of shame and blame from
                traumatic early life experiences such as neglect or abuse.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Compassion Focussed Therapy helps you better understand how your
                mind and bodywork together through evidence-based mind and body
                awareness practices.
              </p>
            </article>
          </section>

          <section class="py-3">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                Mindfulness-Based Cognitive Therapy (MBCT)
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Mindfulness-Based Cognitive Therapy (MBCT) is a form of
                cognitive therapy that employs mindfulness techniques as an
                effective way to detach ourselves from unhelpful and negative
                thoughts that could lead to depression over time.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                We use Mindfulness-Based Cognitive Therapy techniques such as
                breathing exercises and meditation to deal with low mood and
                depression as early and as often as possible before they get
                worse.
              </p>
            </article>
          </section>

          <section class="py-3 very-light-gray-bg">
            <article class="container-1020 px-lg-4 text-center">
              <div
                class="
                  mb-2
                  d-flex
                  justify-content-center
                  align-items-center
                  bg-white
                  rounded-circle
                  mx-auto
                "
                style="height: 110px; width: 110px"
              >
                <b-img
                  fluid
                  rounded="circle"
                  :src="require('@/assets/images/logo-brain.png')"
                  alt="logo brain"
                />
              </div>
              <h2 class="text-dark-gray pb-2 font-weight-normal font-size-36">
                Positive Psychology Therapies
              </h2>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Positive Psychology Therapies focuses on experiences, behaviours
                and ways of thinking that are focused on improving the quality
                of everyday life like being happier, more hopeful, optimistic,
                spiritual, persevering and free-willed.
              </p>
              <p class="text-dark-gray about-and-what-we-do-p font-size-24">
                Positive Psychology Therapies looks at our strengths as a core
                component of this approach as a way to balance out more
                traditional forms of therapy that are driven from addressing
                negative experiences.
              </p>
            </article>
          </section>
          <!-- content start -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import EventBus from "@/main.js";
import { BLink, BModal, BButton, BTable, BImg } from "bootstrap-vue";

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BTable,
    BImg,
  },
  props: {
    size: {
      type: String,
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      items: [
        {
          biological: "Genetic tendencies",
          psychological: "Personality traits",
          social: "Family relationships",
        },
        {
          biological: "Ethnicity",
          psychological: "Coping mechanisms",
          social: "Social group(s)",
        },
        {
          biological: "Medications/Recreation Drugs",
          psychological: "Beliefs",
          social: "Work life",
        },
        {
          biological: "Stress response",
          psychological: "Temperament",
          social: "Culture",
        },
        {
          biological: "Hormones",
          psychological: "Emotion",
          social: "Trauma/Experience",
        },
        {
          biological: "Physical health",
          psychological: "Memories",
          social: "Location",
        },
      ],
    };
  },
  methods: {
    openModal() {
      if (this.$refs["science-modal"] != undefined) {
        // Show modal
        this.$refs["science-modal"].show();
      }
    },
  },
  mounted() {
    EventBus.$on("openScience", () => {
      this.openModal();
    });
  },
};
</script>

<style scoped>
p {
  font-weight: normal;
}

ol {
  padding-left: 1rem;
}

li {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>