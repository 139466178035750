<template>
  <div class="p-0">
      
    <b-link @click="openModal" v-show="show">
        <small v-if="size == 'sm'">How it works</small>
        <div v-else>How it works</div>
    </b-link>

    <b-modal
      id="hiw-modal"
      ref="hiw-modal"
      hide-footer
      centered
      size="lg"
    >
        <template #modal-title> How it works </template>
        <div class="d-block py-2 px-1">
            <p class="font-weight-normal">
            An example: Claire is divorced mum, she is co-parenting with her ex-partner and starting a new business.
            </p>

            <p class="font-weight-normal">
            Based on these life situations that have the greatest impact on her mental health, Claire opts to take three mental health checks.
            </p>

            <p class="font-weight-normal">
                1. Mental health and my divorce
                <br>
                2. Mental health and co-parenting
                <br>
                3. Mental health and starting a new business
            </p>

            <p class="font-weight-normal">
            After completing each mental health check, Claire receives her mental health trait scores.
            </p>
            <p class="font-weight-normal">
              1.Mental health and my divorce - 4 out of 10 
              <br>
              2.Mental health and co-parenting - 7 out of 10
              <br>
              3.Mental health and starting a new business - 5 out of 10
            </p>

            <p class="font-weight-normal">
            From her scores for each mental health check completed, Claire can see that of all the life situations impacting her mental health, her divorce is having the greatest impact and co-parenting having the least impact on her mental health.
            </p>

            <p class="font-weight-normal">
            Based on the scores for each mental health check, Claire receives her overall mental health trait score which is an indicator of her overall mental health based on the life situations she identified as having the greatest impact on her mental health:
            </p>

            <p class="font-weight-normal">
            Claire’s overall mental health trait score – 5.3 out of 10
            </p>

            <p class="font-weight-normal">
            Claire’s mental health trait score is completely unique to her life and the situations that impact her mental health. Claire then signs up to have her mental health checks every month and tracks her mental health progress on her mental health progress chart.
            </p>

            <b-button @click="openForm" variant="primary" block class="mt-3">Start my mental health check</b-button>
        </div>
    </b-modal>
</div>
</template>

<script>
import { BLink, BModal, BButton } from "bootstrap-vue";
import EventBus from "../../main.js"

export default {
  components: {
    BLink,
    BModal,
    BButton
  },
  props: {
      size: {
          type: String
      },
      show: {
          type: Boolean
      }
  },
  methods: {
    openModal() {
      // Show modal
      if(this.$refs['hiw-modal'] != undefined) {
        this.$refs['hiw-modal'].show()
      }
    },
    openForm() {
      if(this.$route.path == '/form') {
        this.$refs['hiw-modal'].hide()
      } else {
        this.$router.push({ path: 'form' })
        this.$refs['hiw-modal'].hide()
      }
    },
  },
  mounted() {
    EventBus.$on('howItWorks', () => {
      this.openModal()
    });
  }
};
</script>
