<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <b-card-title> Community Messages </b-card-title>
      <div v-if="route == 'questionnaire-details'">
        <reactions readOnly="true" :data="data" withSpaces="true" />
      </div>
    </div>
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      :class="fromFinishedTriatSupport ? 'height-350' : 'height-700'"
      suppressScrollX="false"
    >
      <b-overlay :show="show" rounded="sm" class="h-100">
        <app-timeline v-if="reactions.length > 0" style="padding-top: 5px">
          <app-timeline-item
            variant="primary"
            v-for="reaction in reactions"
            :key="reaction.id"
          >
            <div class="d-flex justify-content-between">
              <b-media class="d-flex align-items-center">
                <template #aside>
                  <b-avatar
                    v-if="
                      reaction.userfollow.following_u.roles[0].name == 'User'
                    "
                    :src="makeAvatar(reaction.userfollow.following_u.username)"
                  />
                  <b-avatar
                    v-else
                    :src="makeAvatar(reaction.userfollow.following_u.name)"
                  />
                </template>

                <div class="d-flex align-items-center justify-content-between">
                  <div>
                    <h6
                      v-if="
                        reaction.userfollow.following_u.roles[0].name == 'User'
                      "
                      class="m-0"
                    >
                      {{ reaction.userfollow.following_u.username }}
                    </h6>
                    <h6 v-else class="m-0">
                      {{ reaction.userfollow.following_u.name }}
                    </h6>
                  </div>
                </div>
              </b-media>

              <div
                v-if="
                  reaction.userfollow.following_u.username ==
                    loggedUser.username ||
                  reaction.userfollow.followed_u.username == loggedUser.username
                "
                class="text-center"
              >
                <small class="text-muted">{{
                  reaction.updated_at | prepareDate
                }}</small>
                <div>
                  <b-badge
                    v-if="
                      reaction.userfollow.following_u.roles[0].name == 'User'
                    "
                    pill
                    variant="light-secondary"
                    class="cursor-pointer px-1"
                    @click="removeReaction(reaction.id)"
                    v-b-tooltip.hover.top="'Remove'"
                  >
                    <feather-icon icon="TrashIcon" size="14" />
                  </b-badge>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-between row">
              <div class="col-8 col-lg-9">
                <small class="mt-05">{{ reaction.reaction_text }}</small>
              </div>
              <div
                class="col-4 col-lg-3 text-right"
                v-if="
                  reaction.userfollow.following_u.username !=
                  loggedUser.username
                "
              >
                <follow-button-details
                  v-if="reaction.userfollow.following_u.roles[0].name == 'User'"
                  :id="reaction.userfollow.id"
                  type="follow-you"
                  :user_id="reaction.userfollow.following_u.id"
                  class="mt-05"
                />
                <b-badge
                  pill
                  variant="light-secondary"
                  class="cursor-pointer px-1 ml-05 mt-05"
                  @click="reportReaction(reaction.id)"
                  v-b-tooltip.hover.top="'Report'"
                >
                  <feather-icon icon="FlagIcon" size="14" />
                </b-badge>
              </div>
            </div>

            <div
              class="mt-1 pl-2"
              v-if="reaction.reaction_response_text != null"
            >
              <b-media class="d-flex align-items-center">
                <template #aside>
                  <b-avatar
                    v-if="
                      reaction.userfollow.followed_u.roles[0].name == 'User'
                    "
                    :src="makeAvatar(reaction.userfollow.followed_u.username)"
                  />
                  <b-avatar
                    v-else
                    :src="makeAvatar(reaction.userfollow.followed_u.name)"
                  />
                </template>

                <div>
                  <h6
                    v-if="
                      reaction.userfollow.followed_u.roles[0].name == 'User'
                    "
                    class="m-0"
                  >
                    {{ reaction.userfollow.followed_u.username }}
                  </h6>
                  <h6 v-else class="m-0">
                    {{ reaction.userfollow.followed_u.name }}
                  </h6>
                </div>
              </b-media>
              <div class="mt-05">
                <small>{{ reaction.reaction_response_text }}</small>
                <div
                  v-if="
                    reaction.userfollow.followed_u.username ==
                    loggedUser.username
                  "
                >
                  <b-badge
                    pill
                    variant="light-secondary"
                    class="cursor-pointer px-1 mt-05"
                    @click="removeReactionResponse(reaction.id)"
                    v-b-tooltip.hover.top="'Remove'"
                  >
                    <feather-icon icon="TrashIcon" size="14" /> Remove response
                  </b-badge>
                </div>
              </div>
            </div>

            <div class="mt-1" v-else>
              <div
                v-if="
                  reaction.userfollow.following_u.username !=
                    loggedUser.username &&
                  loggedUser.username == reaction.userfollow.followed_u.username
                "
              >
                <b-badge
                  pill
                  variant="light-secondary"
                  class="cursor-pointer px-1"
                  v-b-toggle="'collapse-comment-' + reaction.id"
                >
                  <feather-icon icon="MessageCircleIcon" size="14" /> Leave a
                  comment
                </b-badge>
                <b-collapse
                  :id="'collapse-comment-' + reaction.id"
                  class="mt-2"
                >
                  <div class="d-flex justify-content-between">
                    <div class="w-100 pr-2">
                      <b-form-textarea
                        size="sm"
                        v-model="message"
                        placeholder="Message"
                        rows="1"
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <b-button
                        variant="primary"
                        class="rounded-circle p-1"
                        @click="sendResponseMessage(reaction.id)"
                        ><feather-icon icon="SendIcon" size="14"
                      /></b-button>
                    </div>
                  </div>
                </b-collapse>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>

        <div v-else class="d-flex flex-column h-100">
          <div class="">
            <span class="mhc-link-underline" @click="inviteFriends()">
              Start building your community by inviting friends and family.
            </span>
          </div>
          <div
            style="flex: 1"
            class="d-flex justify-content-center align-items-center"
          >
            <div class="d-block pb-2 px-5 invite-friends-modal">
              <div class="text-center">
                <h3>Invite friends & family</h3>
                <p>
                  Easily invite your friends and family to start their mental
                  health checks using email, facebook or WhatsApp.
                </p>
                <hr class="mhc-divider m-auto" />
              </div>
              <div class="two-share-buttons pt-2 pb-1 pb-lg-2">
                <b-button style="padding: 1rem" @click="callOpenModal">
                  <div class="mhc-link-no-color" target="_blank">
                    <img src="/fb.png" alt="fb icon" />
                    <span class="font-size-14"> Share via Facebook</span>
                  </div>
                </b-button>

                <b-button style="padding: 1rem" @click="callOpenModal">
                  <div class="mhc-link-no-color">
                    <img src="/wa.png" alt="wa icon" />
                    <span class="font-size-14"> Share via WhatsApp</span>
                  </div>
                </b-button>
              </div>
              <div class="two-share-buttons">
                <b-button
                  class="m-lg-auto"
                  style="padding: 1rem"
                  @click="callOpenModal"
                >
                  <div class="mhc-link-no-color">
                    <img src="/email.png" alt="email icon" />
                    <span class="font-size-14"> Share via Email</span>
                  </div>
                </b-button>
              </div>
            </div>
          </div>
        </div>

        <report-message />
      </b-overlay>
    </vue-perfect-scrollbar>
  </b-card>
</template>

<script>
import EventBus from "../../main.js";
import BCardCode from "@core/components/b-card-code";
import {
  BOverlay,
  BCard,
  BCardTitle,
  BImg,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BFormGroup,
  BFormTextarea,
  BAvatarGroup,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Reactions from "@/components/users/Reactions.vue";
import FollowButtonDetails from "@/components/users/FollowButtonDetails.vue";
import ReportMessage from "@/components/modals/ReportMessage.vue";
import { ref } from "vue-demi";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardCode,
    BOverlay,
    BCard,
    BCardTitle,
    BImg,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BFormGroup,
    BFormTextarea,
    BAvatarGroup,
    BBadge,
    FollowButtonDetails,
    Reactions,
    ReportMessage,
    VuePerfectScrollbar,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    fromFinishedTriatSupport: {
      type: Boolean,
      required: false,
    },
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  setup() {
    // Setup perfect scrollbar
    const perfectScrollbarSettings = {
      wheelPropagation: true,
      suppressScrollX: true,
      useBothWheelAxes: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      data: {},
      loggedUser: "",
      reactions: [],
      message: "",
      show: true,
      myId: "",
      route: "",
    };
  },
  methods: {
    callOpenModal() {
      document.getElementById("openModalId").click();
    },
    // Get all users messages which following me
    getUserMessages() {
      this.$http
        .get("/api/auth/questionnaire/reactions/" + this.myId)
        .then((res) => {
          if(res != undefined) {
            this.reactions = res.data.reactions;
            this.show = false;
          }
        })
        .catch((err) => {
          console.log(err);
          // Hide overlay
          this.show = false;

          // Show toast
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    // Send response message
    sendResponseMessage(reactionId) {
      this.show = true;

      let requestParams = {
        reaction_id: reactionId,
        message: this.message,
      };

      this.$http
        .patch("/api/auth/user/reactions/message/response", requestParams)
        .then((res) => {
          // Reset message
          this.message = "";
          // Refresh messages
          this.getUserMessages();
          // Hide overlay
          this.show = false;
        })
        .catch((err) => {
          // Hide overlay
          this.show = false;

          let errMsg = "";
          if (
            err.response.data.message != null &&
            err.response.data.message != undefined
          ) {
            if (err.response.data.message == "The given data was invalid.") {
              errMsg = "Swear and bad words not allowed";
            } else {
              errMsg = err.response.data.message;
            }
          } else {
            errMsg = "Something went wrong. Please try again";
          }

          // Show toast
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errMsg,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    // Remove message reaction by reaction id
    removeReaction(reactionId) {
      this.$bvModal
        .msgBoxConfirm("Are you sure you want to remove this message?", {
          title: "Are your sure?",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.show = true;
            this.$http
              .delete("/api/auth/user/reactions/" + reactionId)
              .then((res) => {
                // Refresh messages
                this.getUserMessages();
                this.show = false;
              })
              .catch((err) => {
                console.log(err);
                // Hide overlay
                this.show = false;

                // Show toast
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Something went wrong. Please try again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },

    // Remove response message by reaction id
    removeReactionResponse(reactionId) {
      this.$bvModal
        .msgBoxConfirm(
          "Are you sure you want to remove this response message?",
          {
            title: "Are your sure?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Yes",
            cancelTitle: "No",
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value == true) {
            this.show = true;
            this.$http
              .delete("/api/auth/user/reactions/message/response/" + reactionId)
              .then((res) => {
                // Refresh messages
                this.getUserMessages();
                this.show = false;
              })
              .catch((err) => {
                console.log(err);
                // Hide overlay
                this.show = false;

                // Show toast
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Something went wrong. Please try again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                  },
                });
              });
          }
        });
    },
    // Report reaction
    reportReaction(reactionId) {
      EventBus.$emit("openReportModal", reactionId);
    },

    // Open Invite friends modal
    inviteFriends() {
      EventBus.$emit("inviteFriends");
    },
  },
  created() {
    this.myId = this.id;
    this.route = this.$route.name;

    if (this.myId != "") {
      // Get Logged user data
      this.loggedUser = JSON.parse(localStorage.getItem("userData"));

      // Get all reactions (messages)
      this.getUserMessages();
      this.show = true;

      // Fill data for follow and reactions
      this.$http
        .get("/api/auth/questionnaire/results/related/single/" + this.myId)
        .then((res) => {
          if(res != undefined) {
            this.data = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
          // Hide overlay
          this.show = false;

          // Show toast
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    }
    // On newUserMessageLeft event lets refresh messages
    EventBus.$on("newUserMessageLeft", (id) => {
      if (this.myId == id) {
        this.getUserMessages();
      }
    });

    // On report message get fresh list of messages
    EventBus.$on("messageReported", () => {
      this.getUserMessages();
    });
  },
  watch: {
    $props: {
      handler(value) {
        if (value.data != undefined) {
          this.myId = value.data.id;
          this.getUserMessages();
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/modals-style.css";

.height-700 {
  height: 700.2px;
}
.height-350 {
  height: 350.2px;
}
</style>
