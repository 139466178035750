<template>
  <div>
    <b-modal
      id="low-scores-questionnaires"
      ref="low-scores-questionnaires"
      hide-footer
      centered
      title="Have you made contact?"
      size="lg"
      @hidden="closeModal()"
    >
      <b-overlay :show="show" rounded="sm">
        <div class="d-block pt-1 pb-1 px-0 px-lg-5">
            <h4 class="mb-2">Have you made contact regarding your low scores in these questionnaires?</h4>
            <b-list-group>
                <b-list-group-item v-for="questionnaire_result in questionnairesToDisplay" :key="questionnaire_result.id">
                    <div class="d-flex" style="justify-content: space-between;">
                        <div>
                            <b>Questionnaire: </b>{{questionnaire_result.userquestionnairetook.questionnaire.title}}<br>
                            <b>Score: </b>{{questionnaire_result['Questionnaire Score'].section_score}}
                        </div>
                        <div class="d-flex" style="justify-content: space-between; align-items:center; min-width:110px">
                            <b-button variant="primary" size="sm" @click="setPlaceholder(1); setAnswer(1); setQuestionnaireResultId(questionnaire_result.id)">
                                Yes
                            </b-button>
                            <b-button class="ml-1" variant="outline-dark" size="sm" @click="setPlaceholder(0); setAnswer(0); setQuestionnaireResultId(questionnaire_result.id)">
                                No
                            </b-button>
                        </div>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div class="text-center mt-2">
                <a href="#" @click="dontShowAgain()">
                    Don't show this popup next time
                </a>
            </div>
        </div>
      </b-overlay>
    </b-modal>
    <b-modal 
        id="submit-modal"
        ref="submit-modal"
        hide-footer
        centered
        title="Could you give us more info?"
        size="sm">
        <b-form-textarea
            style="overflow:auto"
            id="textarea-answer"
            v-model="answerText"
            :placeholder="placeholderText"
            rows="3"
            max-rows="6">
        </b-form-textarea>
        <b-button variant="primary" size="sm" class="mt-1" @click="submitAnswer()">
            Submit
        </b-button>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BModal, BButton, BProgress, BOverlay, BListGroup, BListGroupItem, BBadge, BCollapse, VBToggle, BFormTextarea } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BProgress,
    BOverlay,
    BListGroup, 
    BBadge,
    BCollapse,
    BListGroupItem,
    VBToggle,
    BFormTextarea,
    ToastificationContent
  },
  directives: { "b-toggle": VBToggle },
  data() {
    return {
      show: true,
      title: "Welcome to Mental Health Check",
      textNumber: "first",
      closeText: "Skip",
      completedSteps: 0,
      score: "",
      notification_id: "",
      questionnairesToDisplay: [],
      placeholderText: "",
      answer: null,
      questionnaireResultId: null,
      answerText: ""
    }
  },
  methods: {
    setPlaceholder(value) {
        if(value == 1) {
            this.placeholderText = "Who did you reach out to? Please provide more details"
        } else {
            this.placeholderText = "Why didn't you reach out? Please provide more details."
        }
    }, 
    dontShowAgain() {
        localStorage.setItem("show_low_scores", 0);
        this.$refs["low-scores-questionnaires"].hide()
    },
    setAnswer(value) {
        this.answer = value;
        this.$refs["submit-modal"].show();
    },
    setQuestionnaireResultId(id) {
        this.questionnaireResultId = id
    },
    closeModal() {
      //this.removeNotification(this.notification_id)
      this.$refs["low-scores-questionnaires"].hide()
    },
    submitAnswer() {
        this.$http.post("/api/auth/user/low-scored-questionnaires", {
            "user_questionnaire_id": this.questionnaireResultId,
            "answer": this.answer,
            "answer_text": this.answerText
        })
        .then((res) => {
            this.$refs["submit-modal"].hide();    
            this.questionnaireResultId = null,
            this.answer = null;
            this.answerText = "";
            this.getLowScoredQuestionnaires();
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Answer submitted!",
                    icon: "CheckSquareIcon",
                    variant: "success",
                },
            });
        })
        .catch((err) => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "There was an error submitting the response!",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
            });
        })
    },
    getLowScoredQuestionnaires() {
      this.$http.get("/api/auth/user/low-scored-questionnaires")
      .then((res) => {
        if(res != undefined) {
          this.questionnairesToDisplay = res.data;
          if(this.questionnairesToDisplay.length > 0) {
              this.$refs["low-scores-questionnaires"].show();
              this.show = false;
          }
        }    
      })
      .catch((err) => {
        console.log(err)
        // Hide overlay
        this.show = false
      })
    }, 
  },
  mounted() {
      if(localStorage.getItem("show_low_scores") != 0) {
        this.getLowScoredQuestionnaires();
      }
  }
};
</script>

<style scoped>

</style>