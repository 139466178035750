<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="count"
        badge-classes="bg-mhc-dark"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="mhc-dark"
        >
          {{ count }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-overlay :show="show" rounded="sm">
        
        <div class="fixed-notifications">
          <b-media v-if="completedQuestionnairesNumber < 3" @click="openLeastThreeCheckModal()">
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-10 cursor-pointer" >
                <div>
                  <small>
                    Finish {{ 3 - completedQuestionnairesNumber }} more mental health checks to get the most accurate view of your mental health.
                  </small>
                </div>
                <div>
                  <small>
                    <b-link :to="{name: 'form'}" class="mhc-link-underline">Start Your Mental Health Check</b-link>
                  </small>
                </div>
              </div>
              <div class="d-flex col-2 justify-content-between">
                <div>
                  <small>Today</small>
                </div>
              </div>
            </div>
          </b-media>
          <b-media v-if="invitesSentNumber < 3" @click="openLeastThreeInvitesModal()">
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-10 cursor-pointer" >
                <div>
                  <small>
                    Invite three people and grow your support community
                  </small>
                </div>
                <div>
                  <small>
                    <span class="mhc-link-underline" @click="inviteFriends()">Invite friends and family</span>
                  </small>
                </div>
              </div>
              <div class="d-flex col-2 justify-content-between">
                <div>
                  <small>Today</small>
                </div>
              </div>
            </div>
          </b-media>
          <b-media v-if="shouldUserDoDailyCheckIn == true">
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-10 cursor-pointer" >
                <div>
                  <small>
                    It's time for your daily check-in
                  </small>
                </div>
                <div>
                  <small>
                    <span class="mhc-link-underline" @click="startDailyCheckIn()">Start my daily check-in now!</span>
                  </small>
                </div>
              </div>
              <div class="d-flex col-2 justify-content-between">
                <div>
                  <small>Today</small>
                </div>
              </div>
            </div>
          </b-media>
        </div>

        <div v-for="(notification, index) in notifications" :key="notification.id">
          <b-media class="" v-if="notification.data.type != 'first-questionnaire-completed' && notification.data.type != 'third-questionnaire-completed' && notification.data.type != 'third-invite-sent'">
            <template #aside>
              <div>
                <b-avatar
                  size="32"
                  :src='makeAvatar(notification.data.info)'
                  variant="light-info"
                />
              </div>
            </template>
            <div class="d-flex justify-content-between align-items-center">
              <div class="col-5 px-25">
                <span>
                  <small>
                  {{ notification.data.info }}
                  </small>
                </span>
              </div>
              <div class="col-3 px-0">
                <small @click="markAsRead(notification, index)" class="mhc-link cursor-pointer">View details</small>
              </div>
              <div class="d-flex col-4 justify-content-between px-25">
                <div>
                  <small>{{ notification.created_at | prepareDate }}</small>
                </div>
                <span><feather-icon
                  icon="XIcon"
                  size="18"
                  class="cursor-pointer"
                  @click="removeNotification(index, notification.id)"
                /></span>
              </div>
            </div>
          </b-media>
        </div>
      </b-overlay>
    </vue-perfect-scrollbar>

    <!-- Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="markAllAsRead()"
    >Mark all notifications as read today</b-button>
    </li>

  <!-- show on notification click -->
  <finished-trait-support />

  </b-nav-item-dropdown>
</template>

<script>
import EventBus from '@/main'
import store from '@/store/index'
import {
  BNavItemDropdown, BOverlay, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import FinishedTraitSupport from "@/components/modals/FinishedTraitSupport.vue"
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BOverlay,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    FinishedTraitSupport,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      notifications: [],
      count: 0,
      show: false,
      completedQuestionnairesNumber: 0,
      invitesSentNumber: 0,
      inviteLinkClicked: false,
      shouldUserDoDailyCheckIn: false
    }
  },
  methods: {
    startDailyCheckIn() {
      EventBus.$emit("myDailyCheckIn")
    },
    checkDailyCheckInStatus() {
       this.$http.get("/api/auth/payment/plan-price")
        .then((res) => {
          if(res.data.price != 0) {
            this.$http.get("/api/auth/has-completed-daily-check-in")
            .then((res) => {
              if(res.data == "") {
                this.shouldUserDoDailyCheckIn = true;
                this.count++;
              } else {
                if(this.shouldUserDoDailyCheckIn == true) {
                  this.count--;
                }
                this.shouldUserDoDailyCheckIn = false;
              }
            }).catch((err) => {
              console.log(err)

              this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Something went wrong. Please try again",
                    icon: "AlertCircleIcon",
                    variant: "danger",
                },
              });
            });
          } else {
            this.shouldUserDoDailyCheckIn = false;
          }     
        }).catch((err) => {

        })
    },

    subscribe () {
      let pusher = store.state.app.pusher
      pusher.subscribe('private-user-' + store.state.app.userData.username)

      // New reaction added and new message reaction added
      pusher.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
        // Open modal for third invite sent
        if(data.type == 'third-invite-sent') {
          EventBus.$emit('leastThreeInvites', '')
        }

        // Append new notification in case if type is not follow request
        if(data.type != 'follow-request-sent' && data.type != 'first-questionnaire-completed' && data.type != 'third-questionnaire-completed' && data.type != 'third-invite-sent') {
          let newNotification = {
            id: data.id,
            data: {
              "core": data.core,
              "info": data.info,
              "type": data.type
            },
            created_at: new Date()
          }
          
          // Push new notification to array
          this.notifications = [newNotification, ...this.notifications]
          this.count++

          // On message/response reaction added lets emit "new user message left" event
          if(data.type == 'new-message-reaction-added' || data.type == 'new-message-response-reaction-added') {
            EventBus.$emit('newUserMessageLeft', data.core.user_questionnaire_id);
          }
        }
      })
    },

    // Remove single notification
    removeNotification(index, id) {
      this.$http.get("/api/auth/user/notifications-mark/" + id)
      .then((res) => {
        this.count--
        this.notifications.splice(index, 1)
      }).catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    },
    
    // Mark as read notification
    markAsRead(notification, index) {
      this.$http.get("/api/auth/user/notifications-mark/" + notification.id)
      .then((res) => {
       
        // Decrement counter and remove notification
        this.removeNotification(index)

        // Redirect user to questionnaire details or open modal if type is not follow request
        if(notification.data.type != 'follow-request-accepted') {

          // When questionnaire is completed
          if(notification.data.type == 'questionnaire-completed') {
            // Emit openFinishedTraitSupportModal event -> open modal
            EventBus.$emit('openFinishedTraitSupportModal', notification.data.core.id);
          } else if(notification.data.type == 'new-message-response-reaction-added') {
            // When user add response to reaction
            EventBus.$emit('openFinishedTraitSupportModal', notification.data.core.user_questionnaire_id);
          } else {
            // If type is reaction
            let id = notification.data.core.user_questionnaire_id
            if(this.$route.params.id != id) {
              this.$router.push({ name: 'questionnaire-details', params: { id } })
            }
          }
        }
        
      })
      .catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      })
    },
    // Mark all notification as read (set counter to 0 and remove all notifications)
    markAllAsRead() {
      this.$http.get("/api/auth/user/notifications-mark")
      .then((res) => {
        // Fixed notification 
        if(this.invitesSentNumber < 3 && this.completedQuestionnairesNumber < 3) {
          this.count = 2
        } else if(this.invitesSentNumber >= 3 && this.completedQuestionnairesNumber < 3) {
          this.count = 1
        } else if(this.invitesSentNumber < 3 && this.completedQuestionnairesNumber >= 3) {
          this.count = 1
        } else {
          this.count = 0
        }

        this.notifications = []
      })
      .catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      })
    },

    // Open modal for invite friends
    inviteFriends() {
      this.inviteLinkClicked = true
      EventBus.$emit('inviteFriends')
    },

    // Open modal for least three checks
    openLeastThreeCheckModal() {
      if(this.completedQuestionnairesNumber >= 3) {
        this.count--
      }
      
      EventBus.$emit('leastThreeChecks', '')
    },
    
    // Open modal for least three invites
    openLeastThreeInvitesModal() {
      if(this.invitesSentNumber >= 3) {
        this.count--
      }

      // Check is clicked inviteFriends
      if(this.inviteLinkClicked == false) {
        EventBus.$emit('leastThreeInvites', '')
      } else {
        this.inviteLinkClicked = false
      }
    },

    // Get number of completed questionnaires
    getNumberOfCompletedQuestionnaires() {
      this.$http.get("/api/auth/user/last-questionnaire")
      .then((res) => {
        if(res != undefined) {
          this.completedQuestionnairesNumber = res.data.number_of_completed_questionnaires
          if(this.completedQuestionnairesNumber < 3) {
            this.count++
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
    },

    // Get number of sent invites
    getInvitesSentNumber() {
      this.$http.get("/api/auth/user/invites-sent")
      .then((res) => {
        if(res != undefined) {
          this.invitesSentNumber = res.data.num_of_invites
          if(this.invitesSentNumber < 3) {
            this.count++
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
    }
  },
  created() {
    this.subscribe();
  },
  mounted() {
    // Get all notifications
    this.$http.get("/api/auth/user/notifications")
    .then((res) => {
      if(res != undefined) {
        this.notifications = res.data
        this.count = res.data.length

        for(let i=0; i<this.notifications.length; i++) {
          // On first questionnaire finished lets emit "leastThreeChecks"
          if(this.notifications[i].data.type == 'first-questionnaire-completed' || this.notifications[i].data.type == 'third-questionnaire-completed') {
            this.count--
            EventBus.$emit('leastThreeChecks', this.notifications[i].id)
          }

          if(this.notifications[i].data.type == 'third-invite-sent') {
            this.count--
            EventBus.$emit('leastThreeInvites', this.notifications[i].id)
          }
        }
      }
    })
    .catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
        return;
    })

    // Get number of completed questionnaires
    this.getNumberOfCompletedQuestionnaires()

    //Get number of invites
    this.getInvitesSentNumber()

    this.checkDailyCheckInStatus();

    EventBus.$on("freshInvitesSentNumber", () => {
      this.getInvitesSentNumber()
    });

    EventBus.$on("dailyCheckInCompleted", () => {
      this.checkDailyCheckInStatus();      
    });

  },
  watch: {
    notifications(value) {
      this.notifications = value
    },
  },
}
</script>

<style>

</style>
