<template>
    <div class="d-flex" v-bind:class="{'ml-1' : withSpaces != 'true'}">
        <b-badge pill variant="light-secondary" class="cursor-pointer" v-bind:class="{'ml-1' : withSpaces == 'true'}" @click="react('&#x1F44D;')">
            <span>&#x1F44D; </span>
            <span>{{ firstReaction }}</span>
        </b-badge>
        <b-badge pill variant="light-secondary" class="cursor-pointer" v-bind:class="{'ml-1' : withSpaces == 'true'}" @click="react('&#x1F917;')">
            <span>&#x1F917;  </span>
            <span>{{ secondReaction }}</span>
        </b-badge>
        <span v-if="readOnly != 'true'">
            <b-badge v-if="withSpaces != 'true' || modalFlag == 'true'" pill variant="light-secondary" class="cursor-pointer ml-1" @click="openModal(data.id)">
                <span>&#x1F48C; </span>
            </b-badge>
            <b-badge v-else pill variant="light-secondary" class="cursor-pointer ml-1" v-b-toggle.collapse-reaction-message>
                <span>&#x1F48C; </span>
            </b-badge>
        </span>
    </div>
</template>

<script>
import EventBus from '@/main'
import { BBadge, BCollapse, VBToggle, BFormGroup, BFormTextarea, BButton } from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        BBadge,
        BCollapse,
        VBToggle,
        BFormGroup,
        BFormTextarea,
        BButton
    },
    props: ["data", "readOnly", "withSpaces", "modalFlag"],
    data() {
        return {
            reactions: [],
            firstReaction: 0,
            secondReaction: 0,
            clickedReaction: "",
        }
    },
    directives: { 'b-toggle': VBToggle },
    methods: {
        react(reaction) {
            // React only if readOnly is not equal true -> it means that users can't click on reactions
            if(this.readOnly != 'true') {
                this.clickedReaction = reaction

                let reactionObj = {
                    reaction_text: reaction,
                    user_questionnaire_id: this.data.id
                }

                // Request to send reaction
                this.$http.post("/api/auth/user/reactions/reaction", reactionObj)
                .then((res) => {
                    // Refresh single trait data
                    this.callFreshSingleTraitData(this.data.id)
                })
                .catch((err) => {
                    console.log(err);

                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Something went wrong. Please try again",
                            icon: "AlertCircleIcon",
                            variant: "danger",
                        },
                    });
                });
            }
        },
        openModal(id) {
            // Open Finished Trait Support modal
            EventBus.$emit('openFinishedTraitSupportModal', id);
        },
        callFreshSingleTraitData(id) {
            // Refresh single trait data
            EventBus.$emit('callFreshUserSameTraitSingleData'+id);
            // Refresh reactions in Finished trait support modal
            EventBus.$emit('getFreshReactionsForFinishedTraitSupport', id)
        },
        reactionsIncrement() {
            if(this.reactions != undefined) {
                this.firstReaction = 0
                this.secondReaction = 0

                if(this.reactions.length > 0) {
                    // Do count for all reactions
                    for(let i=0; i<this.reactions.length; i++) {
                        if(this.reactions[i].reaction_text == '&#x1F44D;') {
                            // Like
                            this.firstReaction++
                        } else if (this.reactions[i].reaction_text == '&#x1F917;') {
                            // Hug
                            this.secondReaction++
                        }
                    }
                }
            }
        }
    },
    mounted() {
        if(typeof(this.data) == 'object' && this.data.id != undefined) {
            this.reactions = this.data.reactions
        }
    },
    watch: { 
        $props: {
            handler(value) {
                this.reactions = value.data.reactions
                this.reactionsIncrement()
            },
        deep: true,
        immediate: true,
        },
    }
}
</script>
