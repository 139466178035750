<template>
  <div>
    <b-modal
      id="least-three-invites-modal"
      ref="least-three-invites-modal"
      hide-footer
      centered
      size="lg"
      @hidden="closeModal()"
    >
      <b-overlay :show="show" rounded="sm">
        <div class="d-block pt-5 pb-1 px-0 px-lg-5">
          <div class="text-center px-0 px-lg-5">
            <h3 class="mb-2">{{ title }}</h3>
            <p v-if="completedSteps == 0">Please invite your {{ textNumber }} trustful person.</p>
            <p v-else>You successfully invited your {{ textNumber }} trustful person.</p>
            <hr class="mhc-divider m-auto" />
            <p class="pt-1" v-html="message"></p>
            <div class="px-3">
              <div class="position-relative mt-3 mx-2">
                <b-progress
                  :value="completedSteps"
                  max="100"
                  variant="mhc-dark"
                  height=".5rem"
                  width="100%"
                  class="mt-2 mb-3"
                />
                <div class="step-1" v-bind:style="{ content: isCompletedStep1 }"></div>
                <div class="step-2" v-bind:style="{ content: isCompletedStep2 }"></div>
                <div class="step-3" v-bind:style="{ content: isCompletedStep3 }"></div>
              </div>
              <b-button v-if="!step3" variant="primary" block class="mt-3 text-uppercase" @click="inviteFriends()">Invite friends & family</b-button>
              <b-button v-else @click="closeModal()" variant="primary" block class="mt-3 text-uppercase">Continue</b-button>
            </div>
            <p class="color-mhc-light mt-3 font-weight-normal cursor-pointer" @click="closeModal()">{{ closeText }}</p>
          </div>
        </div>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { BLink, BModal, BButton, BProgress, BOverlay } from "bootstrap-vue";
import EventBus from "../../main.js"

export default {
  components: {
    BLink,
    BModal,
    BButton,
    BProgress,
    BOverlay
  },
  data() {
    return {
      show: true,
      title: "Give and get support",
      textNumber: "first",
      message: "Invite <span class='color-mhc-dark'>at least three</span> people to give and get support.",
      closeText: "Skip",
      step1: true,
      step2: false,
      step3: false,
      completedSteps: 0,
      invitesSentNumber: 0,
      score: "",
      notification_id: ""
    }
  },
  computed: {
    isCompletedStep1() {
      if(this.step1) {
        this.completedSteps = 50
        return "url('/check_circle.png')"
      } else {
        this.completedSteps = 0
        return "url('/step-1.png')"
      }
    },
    isCompletedStep2() {
      if(this.step2) {
        this.completedSteps = 100
        this.textNumber = "second"
        return "url('/check_circle.png')"
      } else {
        this.completedSteps = 50
        return "url('/step-2.png')"
      }
    },
    isCompletedStep3() {
      if(this.step3) {
        this.title = "Well done!"
        this.textNumber = "third"
        this.message = "Remember that you are not alone. Continue to dashboard for more details on your progress and share your score with loved ones. Invite more your friends, family or colleges.",
        this.closeText = "Done"
        this.completedSteps = 100
        return "url('/check_circle.png')"
      } else {
        return "url('/step-3.png')"
      }
    }
  },
  methods: {
    openModal() {
      if(this.$refs['least-three-invites-modal'] != undefined) {
        // Show modal
        this.$refs["least-three-invites-modal"].show()
      }
    },
    closeModal() {
      //this.removeNotification(this.notification_id)
      this.$refs["least-three-invites-modal"].hide()
    },
    getInvitesSentNumber() {
        this.$http.get("/api/auth/user/invites-sent")
        .then((res) => {
            // Results of last questionnaire
            this.invitesSentNumber = res.data.num_of_invites

            // Number of completed questionnaires
            if(this.invitesSentNumber == 0) {
                this.step1 = false
                this.step2 = false
                this.step3 = false
            } else if(this.invitesSentNumber == 1) {
                this.step1 = true
                this.step2 = false
                this.step3 = false
            } else if(this.invitesSentNumber == 2) { 
                this.step1 = true
                this.step2 = true
                this.step3 = false
            } else {
                this.step1 = true
                this.step2 = true
                this.step3 = true
            }

            // Hide overlay
            this.show = false
        })
        .catch((err) => {
            console.log(err)
            // Hide overlay
            this.show = false
        })
    },
    // Open modal for invite friends
    inviteFriends() {
        EventBus.$emit('inviteFriends')
        this.closeModal()
    },
    removeNotification(id) {
      this.$http.get("/api/auth/user/notifications-mark/" + id)
      .then((res) => {
        // Hide modal
        this.$refs["least-three-invites-modal"].hide()
      }).catch((err) => {
        console.log(err)
        // Hide modal
        this.$refs["least-three-invites-modal"].hide()
      });
    },
  },
  mounted() {
    EventBus.$on("leastThreeInvites", (notif_id) => {
      // Show overlay
      this.show = true
      this.notification_id = notif_id
      
      this.getInvitesSentNumber()
      this.openModal()
    });
  }
};
</script>

<style scoped>
.step-1 {
  position: absolute;
  top: -7px;
  left: -7px;
}

.step-2 {
  position: absolute;
  top: -7px;
  left: 50%;
  margin-left: -10px;
}

.step-3 {
  position: absolute;
  top: -7px;
  right: -7px;
}
</style>

<style>
  #least-three-invites-modal___BV_modal_content_ {
    background-color: #f1f1f1 !important;
  }

  #least-three-invites-modal___BV_modal_body_ {
    background-image: url(/main-bg.jpg)!important;
    background-size: contain!important;
    background-repeat: no-repeat;
    background-position: bottom;
  }
</style>