export default [
  {
    title: 'My Hub',
    route: 'my-hub',
    icon: 'HomeIcon',
  },
  {
    title: 'Start new check',
    route: 'form',
    icon: 'FileTextIcon',
  },
  {
    title: 'My checks',
    route: 'my-checks',
    icon: 'LayersIcon',
  },
  {
    title: 'My daily check-in',
    route: 'my--daily-check-in',
    icon: 'SmileIcon',
  },
  {
    title: 'Learn & Develop',
    route: 'learn-and-develop',
    icon: 'GridIcon',
  },
  {
    title: 'Invite friends',
    route: 'help--invite-friends',
    icon: 'UserPlusIcon',
  }
]
