<template>
    <b-modal
      id="pricing-modal"
      ref="pricing-modal"
      hide-footer
      centered
      size="xl"
      @hide="redirectToForm"
    >
        <section id="pricing-plan" class="mb-3">
            <!-- title text and switch button -->
            <div class="text-center mb-5">
                <b-row>
                    <b-col offset-sm-2
                    sm="10"
                    md="8"
                    offset-lg="2"
                    class="mx-auto mx-0 px-0">
                        <h2 class="mt-5 mb-2 bg-white rounded py-2 color-mhc-dark" style="transform: scale(1.01);">
                            Simple, clear, accessible support
                        </h2>
                    </b-col>
                </b-row>                
                <hr class="mhc-divider m-auto" />
                <h4 v-if="expiredFreePeriod" class="mt-2">
                    Upgrade to premium plan for full access from only £1
                </h4>
            </div>
            <!--/ title text and switch button -->

            <!-- pricing plan cards -->
            <b-row class="pricing-card">
                <b-col
                    offset-sm-2
                    sm="10"
                    md="12"
                    offset-lg="2"
                    lg="10"
                    class="mx-auto"
                >
                    <b-row class="justify-content-center">
                        <b-col xl="5" class="mb-2 mb-xl-0">
                            <b-card
                            align="left"
                            class="py-2"
                            >
                                <h3>Always free</h3>
                                <small>
                                    Get 3 free mental health checks every month
                                </small>

                                <!-- annual plan -->
                                <div class="annual-plan">
                                    <div class="plan-price mt-2">
                                    <sup class="font-medium-1 font-weight-bold color-mhc-dark">£ </sup>
                                    <span class="pricing-basic-value font-weight-bolder color-mhc-dark">0</span>
                                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                    </div>
                                </div>
                                <!--/ annual plan -->

                                <!-- plan benefit -->
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item>
                                        3 free mental health checks per month
                                    </b-list-group-item>
                                </b-list-group>
                                <!--/ plan benefit -->

                                <!-- buttons -->
                                <b-button
                                    block
                                    class="mt-2"
                                    variant="outline-primary"
                                    @click="changeSubscriptionPlan(1)"
                                    v-if="currentSubscriptionPrice == null"
                                >
                                    CURRENT PLAN
                                </b-button>
                                <b-button
                                    block
                                    class="mt-2"
                                    variant="primary"
                                    @click="changeSubscriptionPlan"
                                    v-else
                                >
                                    SELECT
                                </b-button>
                            </b-card>
                        </b-col>
                        
                        <b-col xl="5">
                            <div class="position-absolute d-flex align-items-center justify-content-center px-1" style="background-color: #ff00ff; z-index: 999; padding: 6px; top: -37px;">
                                <h4 class="font-weight-bold text-white mx-0 px-0 mb-0">Best Value</h4>                                
                            </div>
                            <b-card
                            style="border-top-left-radius: 0px;"
                            align="left"
                            class="py-2"
                            >
                                <h3>Premium</h3>
                                <small>Access to all features</small>

                                <!-- annual plan -->
                                <div class="annual-plan">
                                    <div class="row">
                                        <div class="col-12 col-md-4">
                                            <div class="plan-price mt-2">
                                                <sup class="font-medium-1 font-weight-bold color-mhc-dark">£ </sup>
                                                <span class="pricing-basic-value font-weight-bolder color-mhc-dark" style="font-size: 4rem;">1</span>
                                                <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub>
                                            </div>
                                        </div>
                                        <div class="col-12 col-md-8 d-flex align-items-end">
                                            <div>
                                                <p class="mb-0">
                                                    Try Premium free for 7-days
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div class="mt-1 pricing-duration text-body font-weight-bold">
                                        Get unlimited access for just £1/pm for 6 months then £4.99. Cancel anytime.
                                    </div>
                                </div>
                                <!--/ annual plan -->

                                <!-- plan benefit -->
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item>
                                        Unlimited Mental Health Checks
                                    </b-list-group-item>
                                </b-list-group>
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item>
                                        Unlimited support access
                                    </b-list-group-item>
                                </b-list-group>
                                <b-list-group class="list-group-circle text-left">
                                    <b-list-group-item>
                                        Full community access
                                    </b-list-group-item>
                                </b-list-group>
                                <!--/ plan benefit -->

                                <!-- buttons -->
                                <b-button
                                    block
                                    class="mt-2"
                                    variant="outline-primary"
                                    @click="changeSubscriptionPlan(1)"
                                    v-if="currentSubscriptionPrice == productFullPackage"
                                >
                                    CURRENT PLAN
                                </b-button>
                                <div v-else class="text-center">
                                    <b-button
                                        block
                                        class="mt-2 mb-1"
                                        variant="primary"
                                        @click="changeSubscriptionPlan"                                    
                                    >
                                        Start 7-day free trial
                                    </b-button>
                                    <label class="mb-0">Try Mental Health Check premium for free. No commitment. Cancel anytime. We'll remind you <strong>two days</strong> before your Mental Health Check Premium trial ends.</label>
                                </div>                                
                            </b-card>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <!--/ pricing plan cards -->
            
            <!-- <b-row>
                <div class="cursor-pointer mx-auto mb-2" @click="closeModal()">
                No, I don’t need mental health diagnosis
                </div>
            </b-row> -->

        </section>
    </b-modal>
</template>

<script>
import store from "@/store/index";
import EventBus from "../../main.js"
import {
  BModal, BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

/* eslint-disable global-require */
export default {
    components: {
        BModal, 
        BFormCheckbox,
        BButton,
        BCardText,
        BListGroup,
        BListGroupItem,
        BRow,
        BCol,
        BCard,
        BBadge,
        BImg,
        AppCollapseItem,
        AppCollapse,
    },
    data() {
        return {
            status: 'monthly',
            pricing: {},
            selectedPlan: null,
            currentSubscriptionPlan: null,
            currentSubscriptionPrice: null,
            expiredFreePeriod: false,
            isClickedSelectPlan: false,

            // Products
            productFullPackage: store.state.app.productFullPackage,
            fullPackagePrice: store.state.app.fullPackagePrice,
        }
    },
    methods: {
        openModal() {
            if(this.$refs['pricing-modal'] != undefined) {
                // Show modal
                this.$refs["pricing-modal"].show()
            }
        },
        closeModal() {
            // Hide modal
            this.$refs["pricing-modal"].hide()
        },
        changeSubscriptionPlan(isCurrentPlan = 0) {            
            if(isCurrentPlan == 1) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Your are already on this plan",
                        icon: "AlertCircleIcon",
                        variant: "info",
                    },
                });
            } else {
                this.isClickedSelectPlan = true
                this.$router.push("/change-subscription-plan")
                this.closeModal()
            }            
        },
        // Get current subscription plan
        getCurrentSubscriptionPlan() {
            this.$http.get('/api/auth/payment/current-plan')
                .then((response) => {
                    if(response != undefined) {
                        this.currentSubscriptionPlan = response.data.subscriptions
                        if(this.currentSubscriptionPlan.length == 0) {
                            this.currentSubscriptionPrice = null
                        } else {
                            this.currentSubscriptionPrice = response.data.subscriptions[0].stripe_price
                        }
                        this.selectedPlan = this.currentSubscriptionPrice
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        redirectToForm() {
            /*  
                Hide modal event listener (just in case when user hasn't paid plan - expiredFreePeriod == true):

                Scenario 1: User clicked on background to hide modal and value isClickedSelectPlan is false (redirect to /form)
                Scenario 2: User clicked on some button to select current/new plan and we redirect him to /change-subscription-plan
            */
            if(this.expiredFreePeriod) {
                if(this.isClickedSelectPlan == false) {  
                    this.$router.push({ name: 'form' })
                }
            }
        }
    },
    mounted() {
        if(localStorage.getItem('userData') != null) {
            this.getCurrentSubscriptionPlan()
        }

        EventBus.$on("showPricing", (isExpiredFreePeriod) => {
            // If user has free account and has done 3 questionnaires
            this.expiredFreePeriod = isExpiredFreePeriod

            this.openModal()
        });

        EventBus.$on("openPricing", () =>{
            this.openModal()
        });

        EventBus.$on("getCurrentSubscriptionPlan", () => {
            this.getCurrentSubscriptionPlan()
        });
    }
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
<style>

    #pricing-modal___BV_modal_content_ {
        max-width: 1600px;
        margin: auto;
    }
    
    #pricing-modal___BV_modal_content_ {
        background-color: #f1f1f1 !important;
    }

    #pricing-modal___BV_modal_body_ {
        background-image: url(/main-bg.jpg)!important;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
    }
</style>
<style scoped>
    .list-group.list-group-circle .list-group-item:after {
        background-image: url(/check_circle.png);
        background-size: 20px;
    }

    .list-group.list-group-circle .list-group-item:after {
        height: 20px;
        width: 20px;
        margin-top: -6px;
    }
</style>