<template>
  <div
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >
    <!-- Content Left -->
    <div class="content-header-left mb-2">
      <div class="breadcrumbs-top">
        <div
          class="d-flex align-items-center btn-heading-and-breadcrumb-container"
        >
          <b-button
            v-if="$route.meta.pageTitle != 'My Hub'"
            size="sm"
            class="bg-mhc-dark float-left px-0 py-1"
            @click="goUpOne()"
          >
            <feather-icon icon="ChevronLeftIcon" size="22" />
          </b-button>

          <h2
            class="content-header-title float-left pr-1 mb-0 pl-1"
            :class="{'pl-0-important' : $route.meta.pageTitle == 'My Hub'}"
          >
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper flex1">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
    </div>

    <!-- Content Right -->
    <!-- <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1"
      md="3"
      cols="12"
    >
      <b-dropdown
        variant="link"
        no-caret
        toggle-class="p-0"
        right
      >

        <template #button-content>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon"
          >
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </template>


        <b-dropdown-item :to="{ name: 'apps-chat' }">
          <feather-icon
            icon="MessageSquareIcon"
            size="16"
          />
          <span class="align-middle ml-50">Chat</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-email' }">
          <feather-icon
            icon="MailIcon"
            size="16"
          />
          <span class="align-middle ml-50">Email</span>
        </b-dropdown-item>

        <b-dropdown-item :to="{ name: 'apps-calendar' }">
          <feather-icon
            icon="CalendarIcon"
            size="16"
          />
          <span class="align-middle ml-50">Calendar</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-col> -->
  </div>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    FeatherIcon,
  },
  methods: {
    goUpOne() {
      if (this.$route.name == "questionnaire-submissions") {
        this.$router.push("/my-hub");
      } else if (this.$route.name == "questionnaire-details") {
        let back_id = localStorage.getItem("questionnaire_took_id");
        if (back_id != undefined && back_id != null) {
          localStorage.removeItem("questionnaire_took_id");
          this.$router.push("/my-checks/questionnaire-submissions/" + back_id);
        } else {
          this.$router.push("/my-checks");
        }
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>


<style scoped>
.flex1 {
  flex: 1;
}

.pl-0-important{
  padding-left: 0 !important;
}

@media (max-width: 1400px) {
  .btn-heading-and-breadcrumb-container {
    flex-wrap: wrap;
  }
  .btn-heading-and-breadcrumb-container h2{
    flex: 1;
  }

  .breadcrumb-wrapper {
    flex-basis: 100%;
  }

  .breadcrumb {
    padding-left: 0;
    padding-top: 1rem;
  }

  .breadcrumbs-top .content-header-title {
    display: block !important;
  }
}
</style>